import { ref, computed } from 'vue';
import { useLocalStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { useCookies } from 'vue3-cookies';
import { StatusCodes } from 'http-status-codes';
import { regexps } from '@postnord/iam-constants';

import { useDevicesStore } from './devices';
import { useSessionsStore } from './sessions';
import { useDelegateStore } from './delegate';
import { usePendingRequestsStore } from './pendingRequests';
import { useSwitchUserStore } from './switchUser';

import { User, UserAddressVerified } from '@/types/user';
import {
  patchPrimaryEmail,
  checkUser,
  levelDown,
  patchCurrentUser,
  patchPrimaryPhone,
  autoSigninUser,
  fetchCurrentUser,
} from '@/services/UserApiService';
import { UserVerificationBy } from '@/enums/UserVerificationBy';
import { APP_LANGUAGE_COOKIE_NAME } from '@/constants/app';
import { useAppStore } from './app';
import { Delegate } from '@/types/delegate';

export const useUserStore = defineStore('user', () => {
  const appStore = useAppStore();
  const switchUserStore = useSwitchUserStore();

  const { cookies } = useCookies();

  const isFetching = ref(false);
  const setIsFetching = (value: boolean) => (isFetching.value = value);

  const isFetched = ref(false);
  const setIsFetched = (value: boolean) => (isFetched.value = value);

  const isExists = ref(false);

  const persistedUser = useLocalStorage<string | null>('user', null);
  const currentUser = ref<User | null>(null);
  const searchUser = ref<Partial<User> | null>(null);
  const updateUserEmail = ref<string | null>(null);

  const isSearchUserEmail = computed(() => {
    return !!searchUser.value?.email;
  });

  const searchUserIdentifier = computed(() => {
    let value = 'unknown user';

    if (searchUser.value?.email) value = searchUser.value.email;
    if (searchUser.value?.phoneNumber) value = searchUser.value.phoneNumber;

    return value;
  });

  const token = computed(() => currentUser.value?.accessToken);

  const user = computed(() => {
    if (currentUser.value) return currentUser.value;
    return null;
  });

  const hasUser = computed(() => !!currentUser.value);

  const hasPhoneNumber = computed(() => !!currentUser.value?.phoneNumber);

  const isPhoneNumberShared = computed(() =>
    currentUser.value?.phoneNumberShared ? currentUser.value.phoneNumberShared > 0 : false,
  );

  const identifier = computed(() => {
    if (currentUser.value) {
      return currentUser.value.firstName
        ? currentUser.value.lastName
          ? `${currentUser.value.firstName} ${currentUser.value.lastName}`
          : `${currentUser.value.firstName}`
        : `${currentUser.value.email}`;
    }

    return 'Unknown User';
  });

  const isVerified = computed(() => {
    let verified = false;

    if (currentUser.value) {
      if (currentUser.value.addressVerified || currentUser.value.birthdateVerified || currentUser.value.namesVerified) {
        verified = !verified;
      }
    }

    return verified;
  });

  const isVerifiedWith = computed(() => {
    if (!currentUser.value) return null;

    const verificationMethods = {
      mitid: UserVerificationBy.MitID,
      sbid: UserVerificationBy.BankID,
    };

    const verifiedWith = currentUser.value.userVerifiedWith;
    return verifiedWith && verificationMethods.hasOwnProperty(verifiedWith) ? verificationMethods[verifiedWith] : null;
  });

  const verifiedName = computed(() => {
    let name: string | null = null;

    if (currentUser.value) {
      const namesVerified = currentUser.value.namesVerified;

      if (namesVerified) {
        name = `${namesVerified.first_names?.join(' ')} ${namesVerified.family_name}`;
      }
    }

    return name;
  });

  const verifiedBirthDate = computed(() => {
    let birthDate: string | null = null;

    if (currentUser.value) {
      const birthDateVerified = currentUser.value.birthdateVerified;

      if (birthDateVerified) {
        const year = birthDateVerified.slice(0, 4);
        const month = birthDateVerified.slice(4, 6);
        const day = birthDateVerified.slice(6, 8);

        birthDate = `${year}-${month}-${day}`;
      }
    }

    return birthDate;
  });

  const addressVerified = computed(() => {
    let address: UserAddressVerified | null = null;

    if (currentUser.value) {
      const addressVerified = currentUser.value.addressVerified;

      if (addressVerified) {
        address = {
          country: addressVerified.country,
          location: `${addressVerified.street} ${addressVerified.number} ${addressVerified?.unit || ''}`,
          postalcode: `${addressVerified.postalcode} ${addressVerified.city}`,
        };
      }
    }

    return address;
  });

  const setCurrentUser = async () => {
    try {
      const { data, status } = await fetchCurrentUser();

      if (status === StatusCodes.OK) {
        // TODO: Current User must not have tokens, put them in separate stores instead
        const delegateStore = useDelegateStore();
        const pendingRequestsStore = usePendingRequestsStore();
        const sessionStore = useSessionsStore();
        const deviceStore = useDevicesStore();

        if (cookies.get(APP_LANGUAGE_COOKIE_NAME) !== data.preferredLanguage) {
          appStore.setLanguage(data.preferredLanguage);
        }

        delegateStore.setSharedDelegateCount(data.meta.sharedDelegates);
        delegateStore.setAccessedDelegateCount(data.meta.accessedDelegates);
        sessionStore.setCount(data.meta.sessions);
        if (data.sessionId) sessionStore.setSessionId(data.sessionId);
        deviceStore.setCount(data.meta.devices);
        pendingRequestsStore.setRequestsSent(data.meta.requestsSent);
        pendingRequestsStore.setRequestsReceived(data.meta.requestsReceived);

        currentUser.value = data;
        persistedUser.value = JSON.stringify({ userId: data.userId });

        isExists.value = false;
        searchUser.value = null;

        delegateStore.removeRequestedSharedDelegate();

        await switchUserStore.setSwitchUserRequest();
      }
    } catch (error) {
      console.error(error);

      throw error;
    }
  };

  const update = async (body: Partial<User>) => {
    if (!currentUser.value || !token.value) return;

    await patchCurrentUser(token.value, currentUser.value.userId, body);

    if (body.preferredLanguage && cookies.get(APP_LANGUAGE_COOKIE_NAME) == body.preferredLanguage) {
      cookies.set(APP_LANGUAGE_COOKIE_NAME, body.preferredLanguage);
    }
    currentUser.value = { ...currentUser.value, ...body } as User;
  };

  const updateEmail = async (value: string) => {
    if (!currentUser.value || !token.value) return;

    await patchPrimaryEmail(token.value, currentUser.value.userId, value);

    currentUser.value = { ...currentUser.value, email: value } as User;
  };

  const updatePhoneNumber = async (phoneNumber: string, isNew: boolean) => {
    if (!currentUser.value || !token.value) return;

    await patchPrimaryPhone<Delegate>(token.value, currentUser.value.userId, phoneNumber, isNew);

    currentUser.value = { ...currentUser.value, phoneNumber } as User;
  };

  const unverify = async () => {
    if (!currentUser.value || !token.value) return;
    await levelDown(token.value, currentUser.value.userId);

    const {
      firstName,
      lastName,
      email,
      emailVerified,
      emailVerifiedAt,
      phoneNumber,
      phoneNumberVerified,
      preferredLanguage,
      userId,
      meta,
      accessToken,
    } = currentUser.value;

    currentUser.value = Object.assign(
      {},
      {
        firstName,
        lastName,
        email,
        emailVerified,
        emailVerifiedAt,
        phoneNumber,
        phoneNumberVerified,
        preferredLanguage,
        userId,
        meta,
        accessToken,
      },
    ) as User;
  };

  const verifyUser = async (identifier: string) => {
    const {
      data: { isExists },
    } = await checkUser(identifier);

    return isExists;
  };

  const verifySearchUser = async (identifier: string) => {
    const isUserExists = await verifyUser(identifier);
    if (isUserExists) isExists.value = true;

    const isEmail = regexps.EMAIL_REGEX.test(identifier);

    if (isEmail) return (searchUser.value = { email: identifier });
    searchUser.value = { phoneNumber: identifier };
  };

  const setSearchUser = async (value: Partial<User>) => (searchUser.value = value);

  const removeUser = () => (persistedUser.value = null);

  const setUpdateEmail = (value: string) => (updateUserEmail.value = value);

  const autoSignin = async (accessToken: string) => autoSigninUser(accessToken);

  return {
    isFetching,
    setIsFetching,
    isFetched,
    setIsFetched,
    persistedUser,
    currentUser,
    hasPhoneNumber,
    isPhoneNumberShared,
    user,
    setCurrentUser,
    update,
    updateEmail,
    updatePhoneNumber,
    setUpdateEmail,
    updateUserEmail,
    searchUserIdentifier,
    isSearchUserEmail,
    hasUser,
    identifier,
    isVerified,
    isVerifiedWith,
    verifiedName,
    verifiedBirthDate,
    addressVerified,
    unverify,
    verifyUser,
    isExists,
    searchUser,
    setSearchUser,
    verifySearchUser,
    removeUser,
    autoSignin,
  };
});
