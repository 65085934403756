import { logout, startAuth } from '@/services/AppService';
import { useAppStore } from '@/stores/app';
import { useUserStore } from '@/stores/user';

export const useAuth = () => {
  const appStore = useAppStore();
  const userStore = useUserStore();

  const handleLogin = async (redirectPath?: string) => {
    const path = redirectPath ? `/me${redirectPath}` : '/me';
    const { data } = await startAuth(appStore.language || 'en', path);

    window.location.href = data.redirectTo;
  };

  const handleLogout = async () => {
    userStore.removeUser();

    const { data } = await logout();
    window.location.href = data.redirectTo;
  };

  return {
    handleLogin,
    handleLogout,
  };
};
