import * as Sentry from '@sentry/vue';
import { App } from 'vue';
import { Router } from 'vue-router';

import { getEnvironment } from '@/utils/getEnvironment';

export default class MonitoringService {
  public static initializeSentry(app: App<Element>, router: Router) {
    Sentry.init({
      app,
      dsn: process.env.VUE_APP_SENTRY_DSN,
      environment: getEnvironment(),
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
      ],
      beforeSend(event, hint: Sentry.EventHint) {
        const error = hint.originalException as { status: number; code: number };

        // Discard thrown exception if status code is below 500
        if (error?.status < 500 || error?.code < 500) return null;
        return event;
      },
      tracesSampler(samplingContext) {
        // don't trace these paths
        const denyList = [/\/metrics/, /\/health/, /\/healthz/].filter(
          (regex) => samplingContext?.request?.url?.match(regex),
        );
        if (denyList.length > 0) return 0;

        // trace 0.2% of everything else
        return 0.002;
      },

      release: process.env.RELEASE,

      // Needs to be ignored for now, seems to be a non-show stopper for the app
      ignoreErrors: [
        /ResizeObserver\s(loop\scompleted\swith\sundelivered\snotifications\.|loop\s(?:limit\s)?(?:exceeded|reached))/,
      ],
    });
  }
}
