/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { createHead } from '@unhead/vue';

import { applyPolyfills, defineCustomElements } from '@postnord/web-components/loader';
import 'pn-design-assets/pn-assets/styles/pn-styles.scss';
import { PnButton } from '@postnord/web-components/components/pn-button';
import { PnIcon } from '@postnord/web-components/components/pn-icon';
import { PnSpinner } from '@postnord/web-components/components/pn-spinner';
import { PnInput } from '@postnord/web-components/components/pn-input';
import { PnTooltip } from '@postnord/web-components/components/pn-tooltip';
import { PnCheckbox } from '@postnord/web-components/components/pn-checkbox';
import { PnRadioButton } from '@postnord/web-components/components/pn-radio-button';
import { PnSearchField } from '@postnord/web-components/components/pn-search-field';
import { PnAccordion } from '@postnord/web-components/components/pn-accordion';
import { PnAccordionRow } from '@postnord/web-components/components/pn-accordion-row';
import { PnModal } from '@postnord/web-components/components/pn-modal';
import { PnToggleSwitch } from '@postnord/web-components/components/pn-toggle-switch';
import { PnToast } from '@postnord/web-components/components/pn-toast';
import { PnSelect } from '@postnord/web-components/components/pn-select';
import { PnOption } from '@postnord/web-components/components/pn-option';
import { PnNavDropdown } from '@postnord/web-components/components/pn-nav-dropdown';
import { PnTile } from '@postnord/web-components/components/pn-tile';
import { PnRadioTile } from '@postnord/web-components/components/pn-radio-tile';

import App from './App.vue';
import router from './router/index';
import i18n from './i18n';
import VueClickAway from 'vue3-click-away';
import InlineSvg from 'vue-inline-svg';

import MonitoringService from './services/MonitoringService';

import DefaultLayout from '@/layouts/DefaultLayout.vue';
import ProfileLayout from '@/layouts/ProfileLayout.vue';

import Wrapper from '@/components/Wrapper.vue';
import Content from '@/components/Content.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import Toast from '@/components/Toast.vue';
import TopBar from '@/components/TopBar/index.vue';
import CustomModal from '@/components/CustomModal.vue';
import LineSeparator from '@/components/LineSeparator.vue';
import BackLink from '@/components/BackLink.vue';
import LogoIcon from '@/components/icons/LogoIcon.vue';
import DetailsCard from '@/components/DetailsCard.vue';
import Loader from '@/components/Loader.vue';
import Modal from '@/components/Modal.vue';
import Footer from '@/components/Footer/index.vue';

applyPolyfills().then(() => defineCustomElements());

customElements.define('pn-button', PnButton as any);
customElements.define('pn-icon', PnIcon as any);
customElements.define('pn-spinner', PnSpinner as any);
customElements.define('pn-input', PnInput as any);
customElements.define('pn-tooltip', PnTooltip as any);
customElements.define('pn-checkbox', PnCheckbox as any);
customElements.define('pn-radio-button', PnRadioButton as any);
customElements.define('pn-search-field', PnSearchField as any);
customElements.define('pn-accordion', PnAccordion as any);
customElements.define('pn-accordion-row', PnAccordionRow as any);
customElements.define('pn-modal', PnModal as any);
customElements.define('pn-toggle-switch', PnToggleSwitch as any);
customElements.define('pn-toast', PnToast as any);
customElements.define('pn-select', PnSelect as any);
customElements.define('pn-option', PnOption as any);
customElements.define('pn-nav-dropdown', PnNavDropdown as any);
customElements.define('pn-tile', PnTile as any);
customElements.define('pn-radio-tile', PnRadioTile as any);

// Plugins
const pinia = createPinia();
const head = createHead();
const app = createApp(App);

MonitoringService.initializeSentry(app, router);

app.use(i18n);
app.use(pinia);
app.use(router);
app.use(head);
app.use(VueClickAway);

app.component('DefaultLayout', DefaultLayout);
app.component('ProfileLayout', ProfileLayout);

app.component('Wrapper', Wrapper);
app.component('ContentContainer', Content);
app.component('LoadingSpinner', LoadingSpinner);
app.component('Toast', Toast);
app.component('TopBar', TopBar);
app.component('CustomModal', CustomModal);
app.component('LineSeparator', LineSeparator);
app.component('BackLink', BackLink);
app.component('LogoIcon', LogoIcon);
app.component('DetailsCard', DetailsCard);
app.component('Loader', Loader);
app.component('Modal', Modal);
app.component('PageFooter', Footer);
app.component('InlineSvg', InlineSvg);

app.mount('#app');
