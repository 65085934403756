import { defineStore } from 'pinia';
import { Ref, ref } from 'vue';
import { v4 as uuidv4 } from 'uuid';

type Appearance = 'info' | 'success' | 'warning';

interface Toast {
  id: string;
  message: string;
  appearance: Appearance;
}

type ToastPayload = {
  timeout?: number;
} & Omit<Toast, 'id'>;

type ToastPayloadWithoutAppearance = Omit<ToastPayload, 'appearance'>;

const DEFAULT_TIMEOUT = 3000;

export const useToastStore = defineStore('toast', () => {
  const toasts: Ref<Toast[]> = ref([]);

  const setToast = (payload: ToastPayload) => {
    const { message, timeout, appearance } = payload;

    const toast: Toast = {
      id: uuidv4(),
      message,
      appearance,
    };

    toasts.value.push(toast);

    setTimeout(() => close(toast.id), timeout ?? DEFAULT_TIMEOUT);
  };

  const info = (payload: ToastPayloadWithoutAppearance) => setToast({ ...payload, appearance: 'info' });

  const success = (payload: ToastPayloadWithoutAppearance) => setToast({ ...payload, appearance: 'success' });

  const warning = (payload: ToastPayloadWithoutAppearance) => setToast({ ...payload, appearance: 'warning' });

  const close = (id: string) => {
    toasts.value = toasts.value.filter((item) => item.id !== id);
  };

  return { toasts, info, success, warning, close };
});
