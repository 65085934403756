<template>
  <main class="wrapper">
    <slot />
  </main>
</template>

<style lang="scss" scoped>
.wrapper {
  // TODO: Fix padding bottom
  padding: 0 16px 32px 16px;

  @include screen(lg) {
    max-width: 1280px;
    margin: 0 auto;
  }
}
</style>
