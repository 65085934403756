import { get, post } from './HttpService';

export const fetchTerms = (language: string) => {
  return get<string>({
    url: `/tandc?language=${language}`,
  });
};

export const logout = () => {
  return post<null, { redirectTo: string }>({
    url: '/me/oauth2/logout',
  });
};

export const startAuth = (language: string, path: string) => {
  return post<{ language: string; path: string }, { redirectTo: string }>({
    url: '/me/oauth2/start',
    body: {
      language,
      path,
    },
  });
};
