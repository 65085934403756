export enum AccountAction {
  ADD_NEW_CONTACT = 'ADD_NEW_CONTACT',
  REMOVE_CONTACT = 'REMOVE_CONTACT',
  NEW_PRIMARY = 'NEW_PRIMARY',
  EDIT_PRIMARY = 'EDIT_PRIMARY',
  CHANGE_PRIMARY = 'CHANGE_PRIMARY',
  SET_AS_PRIMARY = 'SET_AS_PRIMARY',
  SEE_SHARED_ACCESS = 'SEE_SHARED_ACCESS',
  REVOKE_ACCESS = 'REVOKE_ACCESS',
  DELETE_ACCOUNT = 'DELETE_ACCOUNT',
}
