import { LanguageCode, Languages } from '@/enums/Language';

import SwedenFlag from '@/components/icons/SwedenFlag.vue';
import DenmarkFlag from '@/components/icons/DenmarkFlag.vue';
import FinlandFlag from '@/components/icons/FinlandFlag.vue';
import NorwayFlag from '@/components/icons/NorwayFlag.vue';
import GlobeIcon from '@/components/icons/GlobeIcon.vue';

import { Language } from '@/types/language';

export const LANGUAGE_CODES: Language[] = [
  {
    Flag: SwedenFlag,
    flagId: 'se-flag-icon',
    name: LanguageCode.Svenska.toUpperCase(),
    code: LanguageCode.Svenska,
    text: Languages.Svenska,
    numberPrefix: '+46',
  },
  {
    Flag: DenmarkFlag,
    flagId: 'da-flag-icon',
    name: LanguageCode.Dansk.toUpperCase(),
    code: LanguageCode.Dansk,
    text: Languages.Dansk,
    numberPrefix: '+45',
  },
  {
    Flag: FinlandFlag,
    flagId: 'fi-flag-icon',
    name: LanguageCode.Suomi.toUpperCase(),
    code: LanguageCode.Suomi,
    text: Languages.Suomi,
    numberPrefix: '+358',
  },
  {
    Flag: NorwayFlag,
    flagId: 'no-flag-icon',
    name: LanguageCode.Norsk.toUpperCase(),
    code: LanguageCode.Norsk,
    text: Languages.Norsk,
    numberPrefix: '+47',
  },
  {
    Flag: GlobeIcon,
    flagId: 'en-flag-icon',
    name: LanguageCode.English.toUpperCase(),
    code: LanguageCode.English,
    text: Languages.English,
    numberPrefix: '',
  },
];
