export enum RouteName {
  HOME = 'home',
  MANAGE_ACCOUNT = 'manage-account',
  MANAGE_PASSWORD = 'manage-password',
  MANAGE_DELEGATES = 'manage-connected-accounts',
  MANAGE_DEVICES = 'manage-devices',
  MANAGE_PENDING_REQUESTS = 'manage-pending-requests',
  MANAGE_SESSIONS = 'manage-sessions',
  MANAGE_SWITCH_USER = 'manage-switch-user',
  ABOUT = 'about',
  PROTECT_ACCOUNT = 'protect-account',
  TERMS_CONDITIONS = 'terms-and-conditions',
  SEARCH = 'search',
  SEARCH_DELEGATES = 'search-connected-accounts',
  ACCOUNT_DELETED_CONFIRMATION = 'account-deleted-confirmation',
  NOT_FOUND = 'not-found',
}
