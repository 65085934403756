import { get, remove } from './HttpService';
import { Device } from '@/types/devices';

export const fetchDevices = async (token: string, userId: string) => {
  return get<Device[]>({ url: `/devices/by-user-id/${userId}`, token });
};

export const fetchDeviceModel = async (token: string, deviceName: string, platform: 'Android' | 'iOS') => {
  return get<Device>({ url: `/devices/by-device-name/${deviceName}?platform=${platform}`, token });
};

export const removeDevice = async (token: string, deviceId: string) => {
  return remove({
    url: `/devices/${deviceId}`,
    token,
  });
};
