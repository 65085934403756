import { defineStore } from 'pinia';
import { Ref, computed, ref } from 'vue';

import { SwitchUserRequest, SwitchUserToken } from '@/types/switchUser';
import {
  fetchSwitchUserRequests,
  fetchSwitchUserTokens,
  executeSwitchUser,
  revokeSwitchUserToken,
} from '@/services/SwitchUserApiService';
import { useUserStore } from './user';

export const useSwitchUserStore = defineStore('switchUser', () => {
  const userStore = useUserStore();

  const accessToken = computed(() => userStore.currentUser?.accessToken);
  const currentUser = computed(() => userStore.currentUser);

  const request: Ref<SwitchUserRequest | null> = ref(null);
  const token: Ref<SwitchUserToken | null> = ref(null);

  const hasSwitchUser = computed(() => hasRequest.value || hasAccess.value);

  const hasRequest = computed(() => request.value !== null);
  const hasAccess = computed(() => token.value !== null);

  const setSwitchUserRequest = async () => await Promise.all([getSwitchUserRequests(), getSwitchUserToken()]);

  const getSwitchUserRequests = async () => {
    if (!currentUser.value || !accessToken.value) return;

    const { data } = await fetchSwitchUserRequests(accessToken.value, currentUser.value.userId);

    request.value = data[0] ?? null;
  };

  const getSwitchUserToken = async () => {
    if (!accessToken.value) return;

    const { data } = (await fetchSwitchUserTokens(accessToken.value)) as { data: SwitchUserToken[] };

    let hasValidToken = false;

    if (data.length > 0) {
      const validToken = data.find((item) => item.expiresAt > Math.round(Date.now() / 1000)) ?? null;

      if (validToken) {
        token.value = validToken;
        hasValidToken = validToken !== null;

        return hasValidToken;
      }
    }

    return hasValidToken;
  };

  const execute = async (isApprove: boolean) => {
    if (!request.value?.request_id || !accessToken.value) return;

    const requestId = request.value.request_id;
    await executeSwitchUser(accessToken.value, requestId, isApprove);

    token.value = null;
    request.value = null;
  };

  const revoke = async () => {
    if (!token.value || !accessToken.value) return;

    await revokeSwitchUserToken(token.value.access_token);

    token.value = null;
  };

  return {
    request,
    token,
    hasSwitchUser,
    hasRequest,
    hasAccess,
    setSwitchUserRequest,
    getSwitchUserRequests,
    getSwitchUserToken,
    execute,
    revoke,
  };
});
