import { ref } from 'vue';
import { defineStore } from 'pinia';

import { useAccountStore } from './account';
import { useUserStore } from '@/stores/user';

import { login } from '@/services/LoginApiService';

export const usePasswordStore = defineStore('password', () => {
  const accountStore = useAccountStore();
  const userStore = useUserStore();

  const isVerifying = ref(false);

  const password = ref('');

  const handleInput = (value: string) => (password.value = value);

  const verify = async () => {
    const { currentUser } = userStore;
    if (!currentUser) return;

    try {
      isVerifying.value = true;
      await login({ userName: currentUser.email, password: password.value });

      accountStore.setIsVerified();
    } catch (error) {
      throw error;
    } finally {
      isVerifying.value = false;
    }
  };

  const resetState = () => {
    isVerifying.value = false;
  };

  return {
    isVerifying,
    handleInput,
    verify,
    resetState,
  };
});
