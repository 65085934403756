import { createI18n } from 'vue-i18n';
import { useCookies } from 'vue3-cookies';
import { get } from 'lodash';

import en from '@/locales/en.json';
import da from '@/locales/da.json';
import fi from '@/locales/fi.json';
import no from '@/locales/no.json';
import sv from '@/locales/sv.json';
import { APP_LANGUAGE_COOKIE_NAME } from './constants/app';

const messages = { en, da, fi, no, sv };

const { cookies } = useCookies();

export default createI18n({
  legacy: false,
  locale: cookies.get(APP_LANGUAGE_COOKIE_NAME) ?? 'en',
  fallbackLocale: 'en',
  messageResolver: (obj, path) => {
    const value = get(obj, path, '') as string;

    if (value && typeof value === 'string') {
      return value.replaceAll(/@/g, "{'@'}");
    }

    return null;
  },
  messages,
});
