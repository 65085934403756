export enum LanguageCode {
  Svenska = 'sv',
  Dansk = 'da',
  Suomi = 'fi',
  Norsk = 'no',
  English = 'en',
}

export enum Languages {
  Svenska = 'Svenska',
  Dansk = 'Dansk',
  Suomi = 'Suomi',
  Norsk = 'Norsk',
  English = 'English',
}
