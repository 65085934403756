<template>
  <div class="welcome-image">
    <div class="image-bg">
      <div class="human-with-box">
        <svg xmlns="http://www.w3.org/2000/svg" width="227" height="212" viewBox="0 0 227 212" fill="none">
          <path
            d="M105.354 99.7109L79.6239 174.241L60.6672 211.858L95.0288 211.899L103.908 193.049L105.354 99.7109Z"
            fill="#005E41"
          />
          <path
            d="M103.867 211.858H193.818L178.95 88.1934H122.658C112.292 88.1934 104.073 96.1477 103.867 107.043V211.858Z"
            fill="#0D234B"
          />
          <path
            d="M163.009 200.755L195.388 205.809C214.055 208.709 229.915 192.138 226.239 173.536L215.005 117.069C211.66 100.291 196.957 88.1934 179.9 88.1934H155.946L163.009 200.755Z"
            fill="#0D234B"
          />
          <path d="M185.641 138.613L191.63 172.046L179.653 169.188L185.641 138.613Z" fill="black" />
          <path
            d="M146.943 100.249C153.757 100.249 159.292 94.6978 159.292 87.8621V60.3535H134.635V87.9035C134.594 94.7392 140.128 100.249 146.943 100.249Z"
            fill="#FDEFEE"
          />
          <path
            d="M126.582 36.2429C126.582 36.2429 138.105 52.4 162.348 51.4472C162.348 51.4472 163.793 37.6929 159.539 31.0229C155.286 24.3529 139.385 22.9029 135.131 24.0214C130.836 25.1814 126.954 34.2543 126.582 36.2429Z"
            fill="#042048"
          />
          <path
            d="M148.595 56.1691H164.867C167.552 56.1691 169.947 54.8434 171.475 52.8134C172.466 51.4463 172.921 49.7891 172.962 48.132L173.127 37.2363C173.829 32.7206 170.319 28.6191 165.776 28.6191H148.678V56.1691H148.595Z"
            fill="black"
          />
          <path
            d="M124.641 54.4306L131.662 53.1463L127.078 28.082L112.044 30.8577L115.142 47.8435C115.968 52.3177 120.222 55.2592 124.641 54.4306Z"
            fill="black"
          />
          <path
            d="M135.875 79.4537L143.763 79.2052C156.029 78.8323 165.652 69.6352 165.239 58.6152L164.619 41.6295C164.206 30.6095 153.964 21.9923 141.698 22.3652L139.096 22.448C126.83 22.8209 117.207 32.018 117.62 43.038L118.405 64.788C118.735 73.1566 126.541 79.7437 135.875 79.4537Z"
            fill="#FDEFEE"
          />
          <path
            d="M141.739 19.2571L127.862 4.05285C123.774 -0.172867 117.289 -0.918582 112.375 2.27142C106.428 6.12428 104.941 14.2443 109.195 19.9614L117.744 31.4786L141.739 19.2571Z"
            fill="#00A0D6"
          />
          <path
            d="M117.537 37.9419H150.288C156.69 37.9419 161.935 32.7219 161.935 26.259C161.935 19.8376 156.731 14.5762 150.288 14.5762H117.537C111.136 14.5762 105.891 19.7962 105.891 26.259C105.891 32.7219 111.094 37.9419 117.537 37.9419Z"
            fill="black"
          />
          <path
            d="M103.867 192.386C103.578 192.386 103.371 192.138 103.371 191.889V141.968C103.371 141.678 103.619 141.471 103.867 141.471C104.115 141.471 104.363 141.719 104.363 141.968V191.889C104.404 192.179 104.156 192.386 103.867 192.386Z"
            fill="#002F24"
          />
          <path
            d="M162.059 55.5079H169.204V30.0293H153.881V47.305C153.881 51.8207 157.557 55.5079 162.059 55.5079Z"
            fill="black"
          />
          <path
            d="M166.189 58.9048C169.656 58.9048 172.466 56.0855 172.466 52.6077C172.466 49.1299 169.656 46.3105 166.189 46.3105C162.722 46.3105 159.911 49.1299 159.911 52.6077C159.911 56.0855 162.722 58.9048 166.189 58.9048Z"
            fill="#FDEFEE"
          />
          <path
            d="M164.372 55.2594C164.33 55.2594 164.248 55.2594 164.206 55.218C163.917 55.1351 163.793 54.8451 163.876 54.5551L165.693 49.3351C165.776 49.0451 166.065 48.9209 166.354 49.0037C166.643 49.0866 166.767 49.3766 166.684 49.6666L164.867 54.8866C164.785 55.1351 164.619 55.2594 164.372 55.2594Z"
            fill="#500715"
          />
          <path
            d="M168.625 53.8087H164.991C164.702 53.8087 164.495 53.5602 164.495 53.3116C164.495 53.063 164.743 52.8145 164.991 52.8145H168.625C168.914 52.8145 169.121 53.063 169.121 53.3116C169.121 53.5602 168.873 53.8087 168.625 53.8087Z"
            fill="#500715"
          />
          <path
            d="M138.146 80.9445C137.444 80.9445 136.824 80.9445 136.411 80.9445C136.122 80.9445 135.916 80.6959 135.916 80.4059C135.916 80.1159 136.164 79.8673 136.453 79.9087C141.243 80.033 147.48 79.7016 150.701 78.5002C150.949 78.4173 151.279 78.5416 151.362 78.7902C151.445 79.0387 151.321 79.3702 151.073 79.453C147.562 80.7787 141.491 80.9445 138.146 80.9445Z"
            fill="#500715"
          />
          <path
            d="M140.376 80.9447C137.155 80.9033 134.636 80.9033 134.594 80.9033V79.8262C134.636 79.8262 137.155 79.8262 140.376 79.8676V80.9447Z"
            fill="#500715"
          />
          <path
            d="M172.962 48.2581L137.403 14.5767C137.403 14.5767 147.562 7.32666 163.009 13.9552C163.009 13.9967 182.75 21.3295 172.962 48.2581Z"
            fill="#00A0D6"
          />
          <path d="M159.581 35.4977L138.105 14.3691L147.604 15.902L161.728 32.2249L159.581 35.4977Z" fill="#00A0D6" />
          <path
            d="M169.286 22.1575L170.732 19.6718C171.475 18.3461 171.269 16.6889 170.195 15.6118C168.791 14.2032 166.437 14.3275 165.198 15.9018L163.38 18.2218L169.286 22.1575Z"
            fill="#00A0D6"
          />
          <path d="M145.177 45.4687L155.13 45.4688L155.13 36.6859L145.177 36.6859L145.177 45.4687Z" fill="black" />
          <path
            d="M135.461 48.7957H153.881V44.4457C153.881 40.8414 150.99 37.9414 147.397 37.9414H135.461V48.7957Z"
            fill="#FDEFEE"
          />
          <path
            d="M112.375 179.835H39.9761C34.731 179.835 30.4771 175.568 30.4771 170.306V93.8704C30.4771 88.6089 34.731 84.3418 39.9761 84.3418H112.375C117.62 84.3418 121.874 88.6089 121.874 93.8704V170.306C121.874 175.568 117.62 179.835 112.375 179.835Z"
            fill="#FBC2C1"
          />
          <path
            d="M4.62323 126.93H62.1541C63.5583 126.93 64.9212 127.51 65.9124 128.504L85.5299 148.265H15.9807L1.73223 133.89C-0.828373 131.321 0.988827 126.93 4.62323 126.93Z"
            fill="#0D234B"
          />
          <path
            d="M30.4358 117.193V170.263C30.4358 175.525 34.6897 179.792 39.9348 179.792H112.334C117.579 179.792 121.833 175.525 121.833 170.263V117.193H30.4358Z"
            fill="#005E41"
          />
          <path
            d="M111.755 123.533L97.9199 128.794L84.0844 123.533C83.5475 123.284 83.1758 122.746 83.1758 122.166V117.111H112.623V122.124C112.664 122.746 112.334 123.284 111.755 123.533Z"
            fill="#F06365"
          />
          <path
            d="M98.0025 125.521C99.1201 125.521 100.026 124.612 100.026 123.491C100.026 122.37 99.1201 121.461 98.0025 121.461C96.8848 121.461 95.9788 122.37 95.9788 123.491C95.9788 124.612 96.8848 125.521 98.0025 125.521Z"
            fill="#9B1C2E"
          />
          <g opacity="0.15">
            <path
              opacity="0.15"
              d="M30.4358 148.514L62.1542 120.259C64.4257 118.229 67.358 117.152 70.3729 117.152H121.874V148.307L30.4358 148.514Z"
              fill="#002F24"
            />
          </g>
          <path
            d="M91.601 90.307H60.9564C57.9828 90.307 55.5874 87.9042 55.5874 84.9213V76.8427C55.5874 73.8599 57.9828 71.457 60.9564 71.457H91.601C94.5746 71.457 96.97 73.8599 96.97 76.8427V84.9213C97.0113 87.9042 94.5746 90.307 91.601 90.307Z"
            fill="#FBC2C1"
          />
          <path
            d="M88.1318 83.6361H64.5082C63.0214 83.6361 61.8237 82.4347 61.8237 80.9432V79.5347C61.8237 78.0432 63.0214 76.8418 64.5082 76.8418H88.1318C89.6186 76.8418 90.8163 78.0432 90.8163 79.5347V80.9432C90.8163 82.4347 89.6186 83.6361 88.1318 83.6361Z"
            fill="#500715"
          />
          <path
            d="M76.3201 112.803C76.031 112.803 75.8245 112.555 75.8245 112.306V78.169C75.8245 77.879 76.0723 77.6719 76.3201 77.6719C76.5679 77.6719 76.8157 77.9204 76.8157 78.169V112.265C76.857 112.555 76.6092 112.803 76.3201 112.803Z"
            fill="#500715"
          />
          <path
            d="M79.6654 94.7813C78.6329 94.7813 77.8069 93.9527 77.8069 92.917C77.8069 91.8813 78.6329 91.0527 79.6654 91.0527C80.6979 91.0527 81.5239 91.8813 81.5239 92.917C81.5239 93.9527 80.6979 94.7813 79.6654 94.7813ZM79.6654 92.0884C79.2111 92.0884 78.8394 92.4613 78.8394 92.917C78.8394 93.3727 79.2111 93.7456 79.6654 93.7456C80.1197 93.7456 80.4914 93.3727 80.4914 92.917C80.4914 92.4613 80.1197 92.0884 79.6654 92.0884Z"
            fill="#500715"
          />
          <path
            d="M79.6654 105.719C78.6329 105.719 77.8069 104.89 77.8069 103.855C77.8069 102.819 78.6329 101.99 79.6654 101.99C80.6979 101.99 81.5239 102.819 81.5239 103.855C81.5239 104.89 80.6979 105.719 79.6654 105.719ZM79.6654 103.026C79.2111 103.026 78.8394 103.399 78.8394 103.855C78.8394 104.31 79.2111 104.683 79.6654 104.683C80.1197 104.683 80.4914 104.31 80.4914 103.855C80.4914 103.399 80.1197 103.026 79.6654 103.026Z"
            fill="#500715"
          />
          <g opacity="0.8">
            <path
              opacity="0.8"
              d="M50.4662 126.847H43.4039C42.7844 126.847 42.2888 126.35 42.2888 125.729V97.6401C42.2888 97.0186 42.7844 96.5215 43.4039 96.5215H50.4662C51.0857 96.5215 51.5813 97.0186 51.5813 97.6401V125.729C51.54 126.391 51.0444 126.847 50.4662 126.847Z"
              fill="white"
            />
          </g>
          <path
            d="M46.997 103.316L48.236 100.209H49.2685V104.228H48.5664V101.203L47.3687 104.228H46.6666L45.4689 101.203V104.228H44.7668V100.209H45.7993L46.997 103.316Z"
            fill="black"
          />
          <path
            d="M46.997 112.431L48.236 109.324H49.2685V113.343H48.5664V110.319L47.3687 113.343H46.6666L45.4689 110.319V113.343H44.7668V109.324H45.7993L46.997 112.431Z"
            fill="black"
          />
          <path
            d="M46.997 122.207L48.236 119.1H49.2685V123.118H48.5664V120.094L47.3687 123.118H46.6666L45.4689 120.094V123.118H44.7668V119.1H45.7993L46.997 122.207Z"
            fill="black"
          />
          <path
            d="M60.5848 211.941H27.71C21.1433 211.941 15.8569 206.597 15.8569 200.051V148.266H60.5435V211.941H60.5848Z"
            fill="#005D92"
          />
          <path
            d="M60.5847 211.941H120.47C127.036 211.941 132.323 206.597 132.323 200.051V148.266H60.5847V211.941Z"
            fill="#00A0D6"
          />
          <path
            d="M143.639 126.93H83.8779C82.515 126.93 81.1934 127.468 80.2435 128.463L60.5847 148.307H132.323L146.571 133.973C149.132 131.363 147.273 126.93 143.639 126.93Z"
            fill="#005D92"
          />
          <path
            d="M97.3418 204.899H69.2165C68.5557 204.899 68.0188 204.36 68.0188 203.697V188.244C68.0188 187.582 68.5557 187.043 69.2165 187.043H97.3418C98.0026 187.043 98.5395 187.582 98.5395 188.244V203.697C98.4982 204.36 98.0026 204.899 97.3418 204.899Z"
            fill="#005D92"
          />
          <path
            d="M77.7656 195.536C77.8895 195.453 77.9721 195.287 77.8895 195.163L76.3201 192.636L77.2287 191.517C77.3939 191.31 77.3526 191.02 77.1461 190.854C76.9396 190.688 76.6505 190.73 76.4853 190.937L75.6593 192.138L72.8096 191.31C72.6444 191.268 72.5205 191.351 72.4792 191.517C72.4792 191.641 72.5205 191.724 72.6031 191.766L74.9572 193.091L74.0899 194.334H73.3878C73.2639 194.334 73.14 194.458 73.1813 194.583C73.1813 194.666 73.2639 194.707 73.3052 194.748L73.9247 194.956C73.966 194.956 74.0073 194.997 74.0073 195.038L74.379 195.577C74.4203 195.618 74.5029 195.66 74.5442 195.66C74.6681 195.66 74.7507 195.536 74.7507 195.411L74.5442 194.707L75.5354 193.547L77.4352 195.494C77.5591 195.577 77.6417 195.577 77.7656 195.536Z"
            fill="white"
          />
          <path
            d="M79.1698 202.247H74.2138C73.14 202.247 72.314 201.377 72.314 200.341C72.314 199.264 73.1813 198.436 74.2138 198.436H79.1698C80.2436 198.436 81.0696 199.306 81.0696 200.341C81.0696 201.377 80.2023 202.247 79.1698 202.247Z"
            fill="#00A0D6"
          />
          <path
            d="M92.6749 202.247H84.1671C83.0933 202.247 82.2673 201.377 82.2673 200.341C82.2673 199.264 83.1346 198.436 84.1671 198.436H92.6749C93.7487 198.436 94.5747 199.306 94.5747 200.341C94.5747 201.377 93.7074 202.247 92.6749 202.247Z"
            fill="#8EDDF9"
          />
          <path
            d="M116.175 161.273L142.854 172.459L137.279 182.858L125.839 182.443C123.196 182.361 120.594 181.656 118.24 180.455L112.375 177.472L116.175 161.273Z"
            fill="#FDEFEE"
          />
          <path
            d="M155.162 171.01L142.978 165.044L134.801 155.598C134.099 154.811 133.273 154.148 132.364 153.692L126.83 151.041L132.24 162.144L119.768 161.025L105.85 154.272C104.239 153.485 102.298 154.48 102.009 156.261C101.843 157.297 102.256 158.332 103.124 158.954L104.611 160.031L111.177 164.671L112.623 165.707L153.468 182.527L155.162 171.01Z"
            fill="#FDEFEE"
          />
          <path
            d="M104.941 164.878L114.564 169.518L115.803 164.753L107.088 160.486C105.891 159.906 104.404 160.403 103.826 161.605C103.248 162.848 103.743 164.298 104.941 164.878Z"
            fill="#FDEFEE"
          />
          <path
            d="M106.923 170.139L115.638 174.406L117.785 170.015L109.071 165.748C107.873 165.168 106.386 165.665 105.808 166.867C105.23 168.068 105.726 169.518 106.923 170.139Z"
            fill="#FDEFEE"
          />
          <path
            d="M109.112 176.063L117.827 180.33L119.974 175.939L111.219 171.672C110.021 171.092 108.534 171.589 107.956 172.79C107.378 174.033 107.873 175.483 109.112 176.063Z"
            fill="#FDEFEE"
          />
          <path d="M137.279 182.859L156.855 191.435L155.575 179.172L138.27 173.082L137.279 182.859Z" fill="#FDEFEE" />
          <path d="M132.323 150.544V162.186L121.461 150.42L132.323 150.544Z" fill="#00A0D6" />
          <path d="M146.654 160.859V192.262L157.598 196.447L154.707 160.859H146.654Z" fill="#0D234B" />
        </svg>
      </div>
      <div class="human-bg">
        <svg xmlns="http://www.w3.org/2000/svg" width="475" height="192" viewBox="0 0 475 192" fill="none">
          <path d="M0 96C0 42.9807 42.9807 0 96 0H475V192H96C42.9807 192 0 149.019 0 96Z" fill="#E0F8FF" />
          <path
            d="M434.942 191.691C434.633 191.691 434.413 191.425 434.413 191.159V143.766C434.413 142.127 433.089 140.799 431.457 140.799H401.063V184.604C401.063 184.914 400.798 185.135 400.533 185.135C400.224 185.135 400.004 184.87 400.004 184.604V139.691H431.501C433.751 139.691 435.56 141.507 435.56 143.766V191.115C435.515 191.469 435.251 191.691 434.942 191.691Z"
            fill="#8EDDF9"
          />
          <path
            d="M441.427 192.001H115.294C114.985 192.001 114.72 191.735 114.72 191.469C114.72 191.203 114.985 190.938 115.294 190.938H441.427C441.735 190.938 441.956 191.203 441.956 191.469C441.956 191.735 441.735 192.001 441.427 192.001Z"
            fill="#8EDDF9"
          />
          <path
            d="M209.609 81.7119C209.3 81.7119 209.08 81.4462 209.08 81.1804C209.08 75.1124 204.139 70.1516 198.095 70.1516C193.86 70.1516 189.934 72.632 188.126 76.4854C187.993 76.7512 187.684 76.884 187.376 76.7512C187.111 76.6183 186.979 76.3082 187.111 75.9982C189.096 71.7461 193.419 69 198.095 69C204.756 69 210.182 74.448 210.182 81.1361C210.182 81.4462 209.918 81.7119 209.609 81.7119Z"
            fill="#8EDDF9"
          />
          <path
            d="M245.826 191.468C245.518 191.468 245.297 191.203 245.297 190.937V114.311H223.152C222.843 114.311 222.623 114.045 222.623 113.78C222.623 113.514 222.887 113.248 223.152 113.248H246.4V190.937C246.356 191.203 246.135 191.468 245.826 191.468Z"
            fill="#8EDDF9"
          />
          <path
            d="M217.197 191.468C216.888 191.468 216.667 191.203 216.667 190.937V113.78C216.667 113.47 216.932 113.248 217.197 113.248C217.461 113.248 217.726 113.514 217.726 113.78V190.937C217.77 191.203 217.505 191.468 217.197 191.468Z"
            fill="#8EDDF9"
          />
          <path
            d="M217.197 191.469C216.888 191.469 216.667 191.204 216.667 190.938V81.7128H179.524V132.826C179.524 133.136 179.259 133.358 178.994 133.358C178.73 133.358 178.465 133.092 178.465 132.826V81.137C178.465 80.8269 178.73 80.6055 178.994 80.6055H217.241C217.55 80.6055 217.77 80.8712 217.77 81.137V190.894C217.77 191.204 217.505 191.469 217.197 191.469Z"
            fill="#8EDDF9"
          />
          <path
            d="M217.197 92.3423H179.082C178.774 92.3423 178.553 92.0766 178.553 91.8108C178.553 91.5451 178.818 91.2793 179.082 91.2793H217.197C217.505 91.2793 217.726 91.5451 217.726 91.8108C217.726 92.0766 217.505 92.3423 217.197 92.3423Z"
            fill="#8EDDF9"
          />
          <path
            d="M217.197 103.327H179.082C178.774 103.327 178.553 103.061 178.553 102.795C178.553 102.529 178.818 102.264 179.082 102.264H217.197C217.505 102.264 217.726 102.529 217.726 102.795C217.726 103.061 217.505 103.327 217.197 103.327Z"
            fill="#8EDDF9"
          />
          <path
            d="M217.197 114.311H179.082C178.774 114.311 178.553 114.045 178.553 113.78C178.553 113.514 178.818 113.248 179.082 113.248H217.197C217.505 113.248 217.726 113.514 217.726 113.78C217.726 114.045 217.505 114.311 217.197 114.311Z"
            fill="#8EDDF9"
          />
          <path
            d="M217.197 125.295H179.082C178.774 125.295 178.553 125.03 178.553 124.764C178.553 124.498 178.818 124.232 179.082 124.232H217.197C217.505 124.232 217.726 124.498 217.726 124.764C217.726 125.03 217.505 125.295 217.197 125.295Z"
            fill="#8EDDF9"
          />
          <path
            d="M217.197 136.28H179.082C178.774 136.28 178.553 136.014 178.553 135.748C178.553 135.483 178.818 135.217 179.082 135.217H217.197C217.505 135.217 217.726 135.483 217.726 135.748C217.726 136.014 217.505 136.28 217.197 136.28Z"
            fill="#8EDDF9"
          />
          <path
            d="M217.196 147.221H186.273C185.964 147.221 185.743 146.955 185.743 146.69C185.743 146.424 186.008 146.158 186.273 146.158H217.196C217.505 146.158 217.726 146.424 217.726 146.69C217.726 146.955 217.505 147.221 217.196 147.221Z"
            fill="#8EDDF9"
          />
          <path
            d="M217.196 158.206H186.273C185.964 158.206 185.743 157.94 185.743 157.674C185.743 157.408 186.008 157.143 186.273 157.143H217.196C217.505 157.143 217.726 157.408 217.726 157.674C217.726 157.94 217.505 158.206 217.196 158.206Z"
            fill="#8EDDF9"
          />
          <path
            d="M217.196 169.19H186.273C185.964 169.19 185.743 168.924 185.743 168.658C185.743 168.393 186.008 168.127 186.273 168.127H217.196C217.505 168.127 217.726 168.393 217.726 168.658C217.726 168.924 217.505 169.19 217.196 169.19Z"
            fill="#8EDDF9"
          />
          <path
            d="M211.903 180.174H186.273C185.964 180.174 185.743 179.909 185.743 179.643C185.743 179.377 186.008 179.111 186.273 179.111H211.903C212.212 179.111 212.432 179.377 212.432 179.643C212.432 179.909 212.212 180.174 211.903 180.174Z"
            fill="#8EDDF9"
          />
          <path
            d="M158.878 191.425C158.57 191.425 158.349 191.159 158.349 190.893V117.412H123.102V190.893C123.102 191.203 122.838 191.425 122.573 191.425C122.308 191.425 122.043 191.159 122.043 190.893V116.836C122.043 116.526 122.308 116.305 122.573 116.305H158.878C159.187 116.305 159.408 116.57 159.408 116.836V190.849C159.408 191.159 159.187 191.425 158.878 191.425Z"
            fill="#8EDDF9"
          />
          <path
            d="M155.084 117.412H126.322V116.836C126.322 114.135 128.528 111.92 131.219 111.92H150.232C152.923 111.92 155.129 114.135 155.129 116.836V117.412H155.084ZM127.469 116.305H153.982C153.717 114.445 152.129 113.072 150.232 113.072H131.219C129.322 113.027 127.734 114.445 127.469 116.305Z"
            fill="#8EDDF9"
          />
          <path
            d="M270.707 191.512C270.398 191.512 270.177 191.247 270.177 190.981V117.101C270.177 115.462 269.383 113.912 268.06 112.938L249.4 99.5613C248.826 99.1184 248.076 99.0741 247.415 99.4284C246.753 99.7828 246.356 100.403 246.356 101.156V191.025C246.356 191.335 246.091 191.557 245.826 191.557C245.562 191.557 245.297 191.291 245.297 191.025V101.112C245.297 99.9599 245.915 98.9412 246.929 98.4097C247.944 97.8782 249.135 97.9668 250.061 98.6312L268.721 112.007C270.354 113.159 271.324 115.064 271.324 117.057V190.937C271.28 191.247 271.015 191.512 270.707 191.512Z"
            fill="#8EDDF9"
          />
          <path
            d="M252.002 176.276C251.693 176.276 251.473 176.01 251.473 175.744V118.961C251.473 118.651 251.738 118.43 252.002 118.43C252.267 118.43 252.532 118.695 252.532 118.961V175.7C252.576 176.01 252.311 176.276 252.002 176.276Z"
            fill="#8EDDF9"
          />
          <path
            d="M258.266 178.977C257.958 178.977 257.737 178.712 257.737 178.446V123.213C257.737 122.903 258.002 122.682 258.266 122.682C258.531 122.682 258.796 122.947 258.796 123.213V178.401C258.84 178.712 258.575 178.977 258.266 178.977Z"
            fill="#8EDDF9"
          />
          <path
            d="M264.531 178.978C264.222 178.978 264.001 178.712 264.001 178.446V127.776C264.001 127.466 264.266 127.244 264.531 127.244C264.795 127.244 265.06 127.51 265.06 127.776V178.402C265.104 178.712 264.839 178.978 264.531 178.978Z"
            fill="#8EDDF9"
          />
          <path
            d="M326.51 191.513C326.202 191.513 325.981 191.247 325.981 190.981V99.8716H290.778V190.937C290.778 191.247 290.514 191.468 290.249 191.468C289.984 191.468 289.719 191.203 289.719 190.937V99.3401C289.719 99.0301 289.984 98.8086 290.249 98.8086H326.51C326.819 98.8086 327.04 99.0743 327.04 99.3401V190.981C327.04 191.247 326.819 191.513 326.51 191.513Z"
            fill="#8EDDF9"
          />
          <path
            d="M322.761 99.8723H293.954V97.7463C293.954 95.3102 295.939 93.3613 298.322 93.3613H318.349C320.775 93.3613 322.716 95.3545 322.716 97.7463V99.8723H322.761ZM295.057 98.765H321.658V97.7463C321.658 95.9303 320.202 94.4686 318.393 94.4686H298.366C296.557 94.4686 295.101 95.9303 295.101 97.7463V98.765H295.057Z"
            fill="#8EDDF9"
          />
          <path
            d="M322.187 191.513C321.878 191.513 321.658 191.247 321.658 190.981V110.635H295.057V190.937C295.057 191.247 294.793 191.468 294.528 191.468C294.263 191.468 293.999 191.203 293.999 190.937V110.059C293.999 109.749 294.263 109.527 294.528 109.527H322.231C322.54 109.527 322.761 109.793 322.761 110.059V190.937C322.761 191.247 322.496 191.513 322.187 191.513Z"
            fill="#8EDDF9"
          />
          <path
            d="M299.116 185.976C298.807 185.976 298.586 185.71 298.586 185.445V110.059C298.586 109.749 298.851 109.527 299.116 109.527C299.38 109.527 299.645 109.793 299.645 110.059V185.4C299.689 185.71 299.425 185.976 299.116 185.976Z"
            fill="#8EDDF9"
          />
          <path
            d="M303.748 191.513C303.439 191.513 303.218 191.247 303.218 190.981V110.059C303.218 109.749 303.483 109.527 303.748 109.527C304.012 109.527 304.277 109.793 304.277 110.059V190.937C304.277 191.247 304.056 191.513 303.748 191.513Z"
            fill="#8EDDF9"
          />
          <path
            d="M308.336 191.513C308.027 191.513 307.806 191.247 307.806 190.981V110.059C307.806 109.749 308.071 109.527 308.336 109.527C308.6 109.527 308.865 109.793 308.865 110.059V190.937C308.909 191.247 308.644 191.513 308.336 191.513Z"
            fill="#8EDDF9"
          />
          <path
            d="M312.967 191.513C312.659 191.513 312.438 191.247 312.438 190.981V110.059C312.438 109.749 312.703 109.527 312.967 109.527C313.232 109.527 313.497 109.793 313.497 110.059V190.937C313.541 191.247 313.276 191.513 312.967 191.513Z"
            fill="#8EDDF9"
          />
          <path
            d="M317.599 191.513C317.291 191.513 317.07 191.247 317.07 190.981V110.059C317.07 109.749 317.335 109.527 317.599 109.527C317.864 109.527 318.129 109.793 318.129 110.059V190.937C318.129 191.247 317.908 191.513 317.599 191.513Z"
            fill="#8EDDF9"
          />
          <path
            d="M383.152 191.513C382.844 191.513 382.623 191.248 382.623 190.982V151.606H332.069C331.76 151.606 331.539 151.34 331.539 151.074C331.539 150.809 331.804 150.543 332.069 150.543H383.682V191.026C383.682 191.248 383.461 191.513 383.152 191.513Z"
            fill="#8EDDF9"
          />
          <path
            d="M326.51 191.512C326.202 191.512 325.981 191.246 325.981 190.98V142.17C325.981 141.86 326.246 141.639 326.51 141.639C326.775 141.639 327.04 141.904 327.04 142.17V190.936C327.04 191.246 326.819 191.512 326.51 191.512Z"
            fill="#8EDDF9"
          />
          <path
            d="M290.205 191.512C289.896 191.512 289.675 191.246 289.675 190.981V152.358C289.675 152.048 289.94 151.826 290.205 151.826C290.469 151.826 290.734 152.092 290.734 152.358V190.981C290.734 191.246 290.513 191.512 290.205 191.512Z"
            fill="#8EDDF9"
          />
          <path
            d="M270.707 191.513C270.398 191.513 270.177 191.248 270.177 190.982V151.783H284.779C285.088 151.783 285.308 152.049 285.308 152.315C285.308 152.58 285.044 152.846 284.779 152.846H271.28V190.938C271.28 191.248 271.015 191.513 270.707 191.513Z"
            fill="#8EDDF9"
          />
          <path
            d="M320.423 94.4688H296.689V92.0327C296.689 89.6852 298.586 87.7363 300.968 87.7363H316.188C318.526 87.7363 320.467 89.6409 320.467 92.0327V94.4688H320.423ZM297.792 93.3615H319.32V92.0327C319.32 90.3053 317.908 88.8436 316.144 88.8436H300.924C299.204 88.8436 297.748 90.261 297.748 92.0327V93.3615H297.792Z"
            fill="#8EDDF9"
          />
          <path
            d="M308.556 88.8432C308.247 88.8432 308.027 88.5774 308.027 88.3117V75.8655C308.027 75.5554 308.291 75.334 308.556 75.334C308.821 75.334 309.085 75.5997 309.085 75.8655V88.3117C309.085 88.5774 308.865 88.8432 308.556 88.8432Z"
            fill="#8EDDF9"
          />
          <path
            d="M308.556 74.0051C308.247 74.0051 308.027 73.7394 308.027 73.4736V73.385C308.027 73.075 308.291 72.8535 308.556 72.8535C308.821 72.8535 309.085 73.1193 309.085 73.385V73.4293C309.085 73.7837 308.865 74.0051 308.556 74.0051Z"
            fill="#8EDDF9"
          />
          <path
            d="M428.457 140.798H407.062C406.753 140.798 406.533 140.532 406.533 140.266V133.977C406.533 133.667 406.797 133.445 407.062 133.445H428.457C428.766 133.445 428.987 133.711 428.987 133.977V140.266C428.987 140.576 428.766 140.798 428.457 140.798ZM407.591 139.691H427.884V134.508H407.591V139.691Z"
            fill="#8EDDF9"
          />
          <path
            d="M425.766 134.508C425.457 134.508 425.237 134.243 425.237 133.977C425.237 129.813 421.884 126.447 417.738 126.447C413.591 126.447 410.238 129.813 410.238 133.977C410.238 134.287 409.973 134.508 409.709 134.508C409.444 134.508 409.179 134.243 409.179 133.977C409.179 129.238 413.017 125.34 417.782 125.34C422.502 125.34 426.384 129.193 426.384 133.977C426.34 134.287 426.075 134.508 425.766 134.508Z"
            fill="#8EDDF9"
          />
          <path
            d="M422.502 150.276H410.768C410.459 150.276 410.238 150.01 410.238 149.744C410.238 149.479 410.503 149.213 410.768 149.213H422.502C422.811 149.213 423.031 149.479 423.031 149.744C423.031 150.01 422.811 150.276 422.502 150.276Z"
            fill="#8EDDF9"
          />
          <path
            d="M425.457 157.895H410.768C410.459 157.895 410.238 157.629 410.238 157.364C410.238 157.098 410.503 156.832 410.768 156.832H425.413C425.722 156.832 425.943 157.098 425.943 157.364C425.943 157.629 425.766 157.895 425.457 157.895Z"
            fill="#8EDDF9"
          />
          <path
            d="M425.457 165.557H410.768C410.459 165.557 410.238 165.291 410.238 165.026C410.238 164.76 410.503 164.494 410.768 164.494H425.413C425.722 164.494 425.943 164.76 425.943 165.026C425.943 165.291 425.766 165.557 425.457 165.557Z"
            fill="#8EDDF9"
          />
          <path
            d="M158.878 191.514C158.569 191.514 158.349 191.248 158.349 190.982V145.937C158.349 143.899 159.849 142.26 161.79 141.995V139.736C161.79 137.433 163.643 135.572 165.936 135.572C166.245 135.572 166.466 135.838 166.466 136.104C166.466 136.37 166.201 136.635 165.936 136.635C164.26 136.635 162.893 138.008 162.893 139.691V142.482C162.893 142.792 162.628 143.013 162.363 143.013C160.775 143.013 159.496 144.298 159.496 145.892V190.893C159.408 191.248 159.187 191.514 158.878 191.514Z"
            fill="#8EDDF9"
          />
          <path
            d="M186.008 191.514C185.699 191.514 185.479 191.248 185.479 190.982V145.937C185.479 144.342 184.2 143.058 182.611 143.058C182.303 143.058 182.082 142.792 182.082 142.526V139.736C182.082 138.053 180.715 136.68 179.038 136.68C178.729 136.68 178.509 136.414 178.509 136.148V132.295C178.509 130.833 177.318 129.682 175.906 129.682H169.157C168.054 129.682 167.039 130.39 166.686 131.453C166.598 131.763 166.289 131.896 165.981 131.808C165.672 131.719 165.539 131.409 165.628 131.099C166.157 129.593 167.569 128.574 169.157 128.574H175.906C177.935 128.574 179.612 130.257 179.612 132.295V135.617C181.641 135.882 183.229 137.654 183.229 139.78V142.039C185.17 142.305 186.67 143.988 186.67 145.981V190.982C186.538 191.248 186.317 191.514 186.008 191.514Z"
            fill="#8EDDF9"
          />
          <path
            d="M340.538 191.202C340.23 191.202 340.009 190.937 340.009 190.671V183.008C340.009 181.591 339.039 180.439 337.848 180.439C336.656 180.439 335.686 181.591 335.686 183.008V190.671C335.686 190.981 335.421 191.202 335.157 191.202C334.892 191.202 334.627 190.937 334.627 190.671V183.008C334.627 180.971 336.083 179.332 337.892 179.332C339.7 179.332 341.156 180.971 341.156 183.008V190.671C341.068 190.937 340.847 191.202 340.538 191.202Z"
            fill="#8EDDF9"
          />
          <path
            d="M355.978 191.202C355.67 191.202 355.449 190.937 355.449 190.671V183.008C355.449 181.591 354.479 180.439 353.287 180.439C352.096 180.439 351.126 181.591 351.126 183.008V190.671C351.126 190.981 350.861 191.202 350.596 191.202C350.332 191.202 350.067 190.937 350.067 190.671V183.008C350.067 180.971 351.523 179.332 353.332 179.332C355.14 179.332 356.596 180.971 356.596 183.008V190.671C356.508 190.937 356.287 191.202 355.978 191.202Z"
            fill="#8EDDF9"
          />
          <path
            d="M373.624 191.202C373.315 191.202 373.095 190.937 373.095 190.671V183.008C373.095 181.591 372.124 180.439 370.933 180.439C369.742 180.439 368.771 181.591 368.771 183.008V190.671C368.771 190.981 368.507 191.202 368.242 191.202C367.977 191.202 367.713 190.937 367.713 190.671V183.008C367.713 180.971 369.168 179.332 370.977 179.332C372.786 179.332 374.242 180.971 374.242 183.008V190.671C374.153 190.937 373.933 191.202 373.624 191.202Z"
            fill="#8EDDF9"
          />
          <path
            d="M129.851 191.425C129.543 191.425 129.322 191.159 129.322 190.894V186.819C129.322 186.509 129.587 186.287 129.851 186.287C130.116 186.287 130.381 186.553 130.381 186.819V190.849C130.425 191.159 130.16 191.425 129.851 191.425Z"
            fill="#8EDDF9"
          />
          <path
            d="M133.425 191.424C133.116 191.424 132.895 191.158 132.895 190.893V133.002H130.469V181.547C130.469 181.857 130.204 182.078 129.94 182.078C129.675 182.078 129.41 181.813 129.41 181.547V132.471C129.41 132.161 129.675 131.939 129.94 131.939H133.469C133.778 131.939 133.998 132.205 133.998 132.471V190.893C133.954 191.158 133.734 191.424 133.425 191.424Z"
            fill="#8EDDF9"
          />
          <path
            d="M142.468 191.424C142.159 191.424 141.939 191.158 141.939 190.893V133.002H139.468V190.848C139.468 191.158 139.203 191.38 138.939 191.38C138.674 191.38 138.409 191.114 138.409 190.848V132.471C138.409 132.161 138.674 131.939 138.939 131.939H142.468C142.777 131.939 142.997 132.205 142.997 132.471V190.893C143.041 191.158 142.777 191.424 142.468 191.424Z"
            fill="#8EDDF9"
          />
          <path
            d="M151.555 191.424C151.247 191.424 151.026 191.158 151.026 190.893V133.002H148.6V190.848C148.6 191.158 148.335 191.38 148.07 191.38C147.806 191.38 147.541 191.114 147.541 190.848V132.471C147.541 132.161 147.806 131.939 148.07 131.939H151.599C151.908 131.939 152.129 132.205 152.129 132.471V190.893C152.085 191.158 151.864 191.424 151.555 191.424Z"
            fill="#8EDDF9"
          />
          <path
            d="M169.201 179.731C165.319 179.731 162.187 176.587 162.187 172.689C162.187 168.791 165.319 165.646 169.201 165.646C173.083 165.646 176.215 168.791 176.215 172.689C176.215 176.587 173.083 179.731 169.201 179.731ZM169.201 166.754C165.936 166.754 163.29 169.411 163.29 172.689C163.29 175.967 165.936 178.624 169.201 178.624C172.465 178.624 175.112 175.967 175.112 172.689C175.112 169.411 172.465 166.754 169.201 166.754Z"
            fill="#8EDDF9"
          />
          <path
            d="M169.201 191.513C168.892 191.513 168.672 191.247 168.672 190.982V177.03C168.672 176.72 168.936 176.498 169.201 176.498C169.51 176.498 169.73 176.764 169.73 177.03V190.982C169.73 191.247 169.51 191.513 169.201 191.513Z"
            fill="#8EDDF9"
          />
          <path
            d="M169.201 175.17C168.892 175.17 168.672 174.904 168.672 174.638V174.594C168.672 174.284 168.936 174.062 169.201 174.062C169.51 174.062 169.73 174.328 169.73 174.594V174.638C169.73 174.948 169.51 175.17 169.201 175.17Z"
            fill="#8EDDF9"
          />
          <path
            d="M228.666 129.415H224.431C224.122 129.415 223.902 129.149 223.902 128.883V120.778C223.902 120.468 224.167 120.246 224.431 120.246H228.666C228.975 120.246 229.195 120.512 229.195 120.778V128.883C229.195 129.149 228.975 129.415 228.666 129.415ZM224.961 128.307H228.093V121.309H224.961V128.307Z"
            fill="#8EDDF9"
          />
          <path
            d="M238.592 129.415H234.357C234.048 129.415 233.827 129.149 233.827 128.883V120.778C233.827 120.468 234.092 120.246 234.357 120.246H238.592C238.9 120.246 239.121 120.512 239.121 120.778V128.883C239.165 129.149 238.9 129.415 238.592 129.415ZM234.93 128.307H238.062V121.309H234.93V128.307Z"
            fill="#8EDDF9"
          />
          <path
            d="M228.666 143.501H224.431C224.122 143.501 223.902 143.235 223.902 142.969V134.864C223.902 134.553 224.167 134.332 224.431 134.332H228.666C228.975 134.332 229.195 134.598 229.195 134.864V142.969C229.195 143.235 228.975 143.501 228.666 143.501ZM224.961 142.393H228.093V135.395H224.961V142.393Z"
            fill="#8EDDF9"
          />
          <path
            d="M238.592 143.501H234.357C234.048 143.501 233.827 143.235 233.827 142.969V134.864C233.827 134.553 234.092 134.332 234.357 134.332H238.592C238.9 134.332 239.121 134.598 239.121 134.864V142.969C239.165 143.235 238.9 143.501 238.592 143.501ZM234.93 142.393H238.062V135.395H234.93V142.393Z"
            fill="#8EDDF9"
          />
          <path
            d="M228.666 158.78H224.431C224.122 158.78 223.902 158.514 223.902 158.248V150.143C223.902 149.833 224.167 149.611 224.431 149.611H228.666C228.975 149.611 229.195 149.877 229.195 150.143V158.248C229.195 158.514 228.975 158.78 228.666 158.78ZM224.961 157.673H228.093V150.674H224.961V157.673Z"
            fill="#8EDDF9"
          />
          <path
            d="M238.592 158.78H234.357C234.048 158.78 233.827 158.514 233.827 158.248V150.143C233.827 149.833 234.092 149.611 234.357 149.611H238.592C238.9 149.611 239.121 149.877 239.121 150.143V158.248C239.165 158.514 238.9 158.78 238.592 158.78ZM234.93 157.673H238.062V150.674H234.93V157.673Z"
            fill="#8EDDF9"
          />
          <path
            d="M220.108 114.311H220.064C219.755 114.311 219.535 114.045 219.535 113.78C219.535 113.514 219.799 113.248 220.064 113.248H220.108C220.417 113.248 220.638 113.514 220.638 113.78C220.638 114.045 220.417 114.311 220.108 114.311Z"
            fill="#8EDDF9"
          />
          <path
            d="M287.426 152.889H287.381C287.073 152.889 286.852 152.623 286.852 152.358C286.852 152.092 287.117 151.826 287.381 151.826H287.426C287.734 151.826 287.955 152.092 287.955 152.358C287.955 152.623 287.69 152.889 287.426 152.889Z"
            fill="#8EDDF9"
          />
          <path
            d="M280.456 163.387C280.147 163.387 279.926 163.121 279.926 162.855V162.811C279.926 162.501 280.191 162.279 280.456 162.279C280.72 162.279 280.985 162.545 280.985 162.811V162.855C281.029 163.121 280.764 163.387 280.456 163.387Z"
            fill="#8EDDF9"
          />
          <path
            d="M280.456 169.279C280.147 169.279 279.926 169.013 279.926 168.747V168.658C279.926 168.348 280.191 168.127 280.456 168.127C280.72 168.127 280.985 168.393 280.985 168.658V168.703C281.029 169.013 280.764 169.279 280.456 169.279Z"
            fill="#8EDDF9"
          />
          <path
            d="M280.456 175.125C280.147 175.125 279.926 174.859 279.926 174.593V174.549C279.926 174.239 280.191 174.018 280.456 174.018C280.72 174.018 280.985 174.283 280.985 174.549V174.593C281.029 174.903 280.764 175.125 280.456 175.125Z"
            fill="#8EDDF9"
          />
          <path
            d="M186.935 79.2323C186.626 79.2323 186.405 78.9666 186.405 78.7008V78.6565C186.405 78.3465 186.67 78.125 186.935 78.125C187.199 78.125 187.464 78.3908 187.464 78.6565V78.7008C187.508 79.0108 187.243 79.2323 186.935 79.2323Z"
            fill="#8EDDF9"
          />
          <path
            d="M165.892 134.242C165.584 134.242 165.363 133.976 165.363 133.711V133.666C165.363 133.356 165.628 133.135 165.892 133.135C166.157 133.135 166.422 133.401 166.422 133.666V133.711C166.466 134.021 166.201 134.242 165.892 134.242Z"
            fill="#8EDDF9"
          />
          <path
            d="M252.046 178.978C251.738 178.978 251.517 178.713 251.517 178.447V178.403C251.517 178.093 251.782 177.871 252.046 177.871C252.311 177.871 252.576 178.137 252.576 178.403V178.447C252.576 178.713 252.355 178.978 252.046 178.978Z"
            fill="#8EDDF9"
          />
          <path
            d="M129.851 185.047C129.543 185.047 129.322 184.781 129.322 184.515V184.471C129.322 184.161 129.587 183.939 129.851 183.939C130.116 183.939 130.381 184.205 130.381 184.471V184.515C130.425 184.781 130.16 185.047 129.851 185.047Z"
            fill="#8EDDF9"
          />
          <path
            d="M299.116 188.635C298.807 188.635 298.586 188.369 298.586 188.103V188.059C298.586 187.749 298.851 187.527 299.116 187.527C299.38 187.527 299.645 187.793 299.645 188.059V188.103C299.689 188.413 299.425 188.635 299.116 188.635Z"
            fill="#8EDDF9"
          />
          <path
            d="M425.457 150.276H425.413C425.104 150.276 424.884 150.01 424.884 149.744C424.884 149.479 425.149 149.213 425.413 149.213H425.457C425.766 149.213 425.987 149.479 425.987 149.744C425.987 150.01 425.766 150.276 425.457 150.276Z"
            fill="#8EDDF9"
          />
          <path
            d="M214.594 180.174H214.55C214.241 180.174 214.021 179.909 214.021 179.643C214.021 179.377 214.285 179.111 214.55 179.111H214.594C214.903 179.111 215.123 179.377 215.123 179.643C215.123 179.909 214.859 180.174 214.594 180.174Z"
            fill="#8EDDF9"
          />
          <path
            d="M166.775 152.889H166.731C166.422 152.889 166.201 152.623 166.201 152.358C166.201 152.092 166.466 151.826 166.731 151.826H166.775C167.083 151.826 167.304 152.092 167.304 152.358C167.304 152.623 167.083 152.889 166.775 152.889Z"
            fill="#8EDDF9"
          />
          <path
            d="M172.642 152.889H172.554C172.245 152.889 172.024 152.623 172.024 152.358C172.024 152.092 172.289 151.826 172.554 151.826H172.598C172.906 151.826 173.127 152.092 173.127 152.358C173.127 152.623 172.951 152.889 172.642 152.889Z"
            fill="#8EDDF9"
          />
          <path
            d="M178.465 152.889H178.421C178.112 152.889 177.891 152.623 177.891 152.358C177.891 152.092 178.156 151.826 178.421 151.826H178.465C178.774 151.826 178.994 152.092 178.994 152.358C178.994 152.623 178.774 152.889 178.465 152.889Z"
            fill="#8EDDF9"
          />
          <path
            d="M329.29 151.606H329.245C328.937 151.606 328.716 151.34 328.716 151.074C328.716 150.809 328.981 150.543 329.245 150.543H329.29C329.598 150.543 329.819 150.809 329.819 151.074C329.819 151.34 329.598 151.606 329.29 151.606Z"
            fill="#8EDDF9"
          />
          <path
            d="M411.12 191.689C410.812 191.689 410.591 191.423 410.591 191.157C410.591 187.437 407.547 184.381 403.842 184.381C400.136 184.381 397.092 187.437 397.092 191.157C397.092 191.468 396.827 191.689 396.563 191.689C396.298 191.689 396.033 191.423 396.033 191.157C396.033 186.817 399.563 183.273 403.886 183.273C408.209 183.273 411.738 186.817 411.738 191.157C411.694 191.468 411.429 191.689 411.12 191.689Z"
            fill="#8EDDF9"
          />
          <path
            d="M400.445 185.136C400.136 185.136 399.916 184.87 399.916 184.604V107.092H383.152C382.844 107.092 382.623 106.827 382.623 106.561C382.623 106.295 382.888 106.029 383.152 106.029H400.445C400.754 106.029 400.974 106.295 400.974 106.561V184.604C400.974 184.914 400.754 185.136 400.445 185.136Z"
            fill="#8EDDF9"
          />
          <path
            d="M383.152 191.691C382.844 191.691 382.623 191.425 382.623 191.159V110.68C382.623 110.37 382.888 110.148 383.152 110.148C383.417 110.148 383.682 110.414 383.682 110.68V191.159C383.682 191.469 383.461 191.691 383.152 191.691Z"
            fill="#8EDDF9"
          />
          <path
            d="M400.445 107.093H383.152C382.976 107.093 382.8 107.004 382.711 106.871C382.623 106.738 382.579 106.517 382.667 106.384L384.873 100.36C384.961 100.139 385.138 100.006 385.402 100.006H398.283C398.504 100.006 398.725 100.139 398.813 100.36L401.019 106.384C401.063 106.561 401.063 106.738 400.974 106.871C400.798 107.004 400.621 107.093 400.445 107.093ZM383.947 105.985H399.651L397.842 101.069H385.711L383.947 105.985Z"
            fill="#8EDDF9"
          />
          <path
            d="M399.342 104.081H384.255C383.946 104.081 383.726 103.815 383.726 103.549C383.726 103.283 383.991 103.018 384.255 103.018H399.342C399.651 103.018 399.871 103.283 399.871 103.549C399.871 103.815 399.651 104.081 399.342 104.081Z"
            fill="#8EDDF9"
          />
          <path
            d="M395.284 101.068H388.27C387.961 101.068 387.74 100.802 387.74 100.536V90.4378C387.74 90.1277 388.005 89.9062 388.27 89.9062C388.534 89.9062 388.799 90.172 388.799 90.4378V99.9606H394.71V93.9811C394.71 93.6711 394.975 93.4496 395.24 93.4496C395.504 93.4496 395.769 93.7154 395.769 93.9811V100.492C395.857 100.802 395.593 101.068 395.284 101.068Z"
            fill="#8EDDF9"
          />
          <path
            d="M398.107 90.8806H385.49C385.27 90.8806 385.049 90.7477 384.961 90.5263C384.873 90.3048 384.961 90.0391 385.138 89.9062L391.446 84.8126C391.666 84.6354 391.931 84.6354 392.152 84.8126L398.46 89.9062C398.636 90.0391 398.725 90.3048 398.636 90.5263C398.548 90.7477 398.328 90.8806 398.107 90.8806ZM387.034 89.7733H396.519L391.799 85.9642L387.034 89.7733Z"
            fill="#8EDDF9"
          />
          <path
            d="M391.799 101.068C391.49 101.068 391.269 100.802 391.269 100.536V90.4378C391.269 90.1277 391.534 89.9062 391.799 89.9062C392.063 89.9062 392.328 90.172 392.328 90.4378V100.492C392.328 100.802 392.107 101.068 391.799 101.068Z"
            fill="#8EDDF9"
          />
          <path
            d="M391.799 85.9198C391.666 85.9198 391.534 85.8755 391.402 85.7426L388.799 83.1293C388.578 82.9079 388.578 82.5535 388.799 82.3321L391.402 79.7188C391.622 79.4974 391.975 79.4974 392.196 79.7188L394.798 82.3321C395.019 82.5535 395.019 82.9079 394.798 83.1293L392.196 85.7426C392.063 85.8755 391.931 85.9198 391.799 85.9198ZM389.99 82.775L391.799 84.591L393.607 82.775L391.799 80.959L389.99 82.775Z"
            fill="#8EDDF9"
          />
          <path
            d="M391.799 80.9589C391.49 80.9589 391.269 80.6932 391.269 80.4274V76.2639C391.269 75.9539 391.534 75.7324 391.799 75.7324C392.063 75.7324 392.328 75.9982 392.328 76.2639V80.4274C392.372 80.7375 392.107 80.9589 391.799 80.9589Z"
            fill="#8EDDF9"
          />
          <path
            d="M389.108 73.5626H385.667C385.446 73.5626 385.226 73.4298 385.138 73.164L384.697 71.8352C384.608 71.5252 384.741 71.2151 385.049 71.1266C385.358 71.038 385.667 71.1708 385.755 71.4809L386.064 72.411H388.711L389.02 71.4809C389.108 71.1708 389.417 71.038 389.725 71.1266C390.034 71.2151 390.167 71.5252 390.078 71.8352L389.637 73.164C389.549 73.4298 389.373 73.5626 389.108 73.5626Z"
            fill="#8EDDF9"
          />
          <path
            d="M387.387 73.5627C387.078 73.5627 386.858 73.297 386.858 73.0312V70.9495C386.858 70.6394 387.123 70.418 387.387 70.418C387.652 70.418 387.917 70.6837 387.917 70.9495V73.0312C387.961 73.297 387.696 73.5627 387.387 73.5627Z"
            fill="#8EDDF9"
          />
          <path
            d="M398.372 73.5626H394.931C394.71 73.5626 394.49 73.4298 394.402 73.164L393.96 71.8352C393.872 71.5252 394.005 71.2151 394.313 71.1266C394.622 71.038 394.931 71.1708 395.019 71.4809L395.328 72.411H397.975L398.284 71.4809C398.372 71.1708 398.681 71.038 398.989 71.1266C399.298 71.2151 399.431 71.5252 399.342 71.8352L398.901 73.164C398.813 73.4298 398.592 73.5626 398.372 73.5626Z"
            fill="#8EDDF9"
          />
          <path
            d="M396.651 73.5627C396.343 73.5627 396.122 73.297 396.122 73.0312V70.9495C396.122 70.6394 396.387 70.418 396.651 70.418C396.916 70.418 397.181 70.6837 397.181 70.9495V73.0312C397.181 73.297 396.96 73.5627 396.651 73.5627Z"
            fill="#8EDDF9"
          />
          <path
            d="M393.519 76.5744H390.078C389.858 76.5744 389.637 76.4415 389.549 76.1757L389.108 74.8469C389.02 74.5369 389.152 74.2269 389.461 74.1383C389.77 74.0497 390.078 74.1826 390.167 74.4926L390.475 75.4227H393.122L393.431 74.4926C393.519 74.1826 393.828 74.0497 394.137 74.1383C394.446 74.2269 394.578 74.5369 394.49 74.8469L394.049 76.1757C393.96 76.3972 393.74 76.5744 393.519 76.5744Z"
            fill="#8EDDF9"
          />
          <path
            d="M391.799 76.5744C391.49 76.5744 391.269 76.3087 391.269 76.0429V73.9612C391.269 73.6511 391.534 73.4297 391.799 73.4297C392.063 73.4297 392.328 73.6954 392.328 73.9612V76.0429C392.372 76.3087 392.107 76.5744 391.799 76.5744Z"
            fill="#8EDDF9"
          />
          <path
            d="M388.931 117.678C388.623 117.678 388.402 117.412 388.402 117.146V111.477C388.402 111.167 388.667 110.945 388.931 110.945C389.196 110.945 389.461 111.211 389.461 111.477V117.146C389.461 117.412 389.24 117.678 388.931 117.678Z"
            fill="#8EDDF9"
          />
          <path
            d="M394.666 117.678C394.358 117.678 394.137 117.412 394.137 117.146V111.477C394.137 111.167 394.402 110.945 394.666 110.945C394.931 110.945 395.196 111.211 395.196 111.477V117.146C395.196 117.412 394.975 117.678 394.666 117.678Z"
            fill="#8EDDF9"
          />
          <path
            d="M334.671 164.804C334.363 164.804 334.142 164.539 334.142 164.273V159.401C334.142 159.091 334.407 158.869 334.671 158.869C334.936 158.869 335.201 159.135 335.201 159.401V164.273C335.201 164.539 334.98 164.804 334.671 164.804Z"
            fill="#8EDDF9"
          />
          <path
            d="M342.568 164.804C342.259 164.804 342.039 164.539 342.039 164.273V159.401C342.039 159.091 342.303 158.869 342.568 158.869C342.833 158.869 343.097 159.135 343.097 159.401V164.273C343.141 164.539 342.877 164.804 342.568 164.804Z"
            fill="#8EDDF9"
          />
          <path
            d="M350.508 164.804C350.199 164.804 349.979 164.539 349.979 164.273V159.401C349.979 159.091 350.243 158.869 350.508 158.869C350.773 158.869 351.037 159.135 351.037 159.401V164.273C351.082 164.539 350.817 164.804 350.508 164.804Z"
            fill="#8EDDF9"
          />
          <path
            d="M358.449 164.804C358.14 164.804 357.919 164.539 357.919 164.273V159.401C357.919 159.091 358.184 158.869 358.449 158.869C358.713 158.869 358.978 159.135 358.978 159.401V164.273C358.978 164.539 358.757 164.804 358.449 164.804Z"
            fill="#8EDDF9"
          />
          <path
            d="M366.389 164.804C366.08 164.804 365.86 164.539 365.86 164.273V159.401C365.86 159.091 366.125 158.869 366.389 158.869C366.654 158.869 366.919 159.135 366.919 159.401V164.273C366.919 164.539 366.698 164.804 366.389 164.804Z"
            fill="#8EDDF9"
          />
          <path
            d="M374.286 164.804C373.977 164.804 373.756 164.539 373.756 164.273V159.401C373.756 159.091 374.021 158.869 374.286 158.869C374.55 158.869 374.815 159.135 374.815 159.401V164.273C374.859 164.539 374.594 164.804 374.286 164.804Z"
            fill="#8EDDF9"
          />
          <path
            d="M383.152 108.995C382.844 108.995 382.623 108.73 382.623 108.464V108.375C382.623 108.065 382.888 107.844 383.152 107.844C383.417 107.844 383.682 108.11 383.682 108.375V108.464C383.682 108.774 383.461 108.995 383.152 108.995Z"
            fill="#8EDDF9"
          />
          <path
            d="M395.284 92.6516C394.975 92.6516 394.754 92.3858 394.754 92.1201V92.0315C394.754 91.7215 395.019 91.5 395.284 91.5C395.548 91.5 395.813 91.7658 395.813 92.0315V92.0758C395.857 92.3858 395.592 92.6516 395.284 92.6516Z"
            fill="#8EDDF9"
          />
          <path
            d="M258.569 68.8611C258.569 60.557 265.28 53.9023 273.527 53.9023C281.831 53.9023 288.486 60.6139 288.486 68.8611H258.569Z"
            fill="white"
          />
          <path
            d="M229.675 68.8611C229.675 60.557 236.386 53.9023 244.634 53.9023C252.938 53.9023 259.592 60.6139 259.592 68.8611H229.675Z"
            fill="white"
          />
          <path
            d="M62 41.9587C62 33.6547 68.7115 27 76.9587 27C85.206 27 91.9175 33.7115 91.9175 41.9587H62Z"
            fill="white"
          />
          <path
            d="M436.083 44.1209C436.083 35.8168 442.794 29.1621 451.041 29.1621C459.289 29.1621 466 35.8736 466 44.1209H436.083Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.welcome-image {
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0;

  @media only screen and (min-width: 600px) {
    margin-bottom: 48px;
  }

  @media only screen and (min-width: 1078px) {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    height: 100%;
    margin-bottom: 0;
  }

  .image-bg {
    display: none;

    @media only screen and (min-width: 600px) {
      position: relative;
      display: block;
    }

    .human-with-box {
      position: absolute;
      bottom: 0;
      margin-left: 40px;
    }
  }
}
</style>
