<template>
  <section class="account__logout">
    <section class="account__logout_illustration">
      <pn-illustration illustration="globe-hand" />
    </section>

    <h1>{{ $t('manage_account_page.logout_required') }}</h1>
    <span>{{ $t('manage_account_page.logout_required_caption') }}</span>
  </section>
</template>

<style lang="scss" scoped>
.account {
  &__logout {
    text-align: center;

    h1 {
      margin: 10px 0;
    }

    &_illustration {
      width: 166px;
      margin: 0 auto;
      margin-bottom: 24px;
    }
  }
}
</style>
