<template>
  <section class="content">
    <slot />
  </section>
</template>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;

  @include screen(md) {
    width: 775px;
  }
}
</style>
