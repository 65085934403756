import { Session } from '@/types/session';
import { get, remove, post } from './HttpService';
import { ClientSession } from '@/types/clientSession';

export const fetchSessions = async (token: string, userId: string) => {
  return get<Session[]>({ url: `/sessions/${userId}`, token });
};

export const fetchClientSessions = async (token: string) => {
  return get<ClientSession[]>({ url: '/sessions/clients', token });
};

export const removeSession = async (token: string, sessionId: string) => {
  return remove({
    url: `/sessions/${sessionId}`,
    token,
  });
};

export const removeClientSession = async (token: string, clientId: string) => {
  return remove({ url: `/sessions/clients/${clientId}`, token });
};

export const logout = async (token: string) => {
  return post({
    url: '/sessions/logout',
    token,
  });
};
