import { createRouter, createWebHistory } from 'vue-router';
import qs from 'query-string';

import HomeViewVue from '@/views/HomeView.vue';
import AboutViewVue from '@/views/AboutView.vue';
import NotFoundView from '@/views/NotFoundView.vue';

import { homeRoutes } from './home';
import { aboutRoutes } from './about';

import { RoutePath } from '@/enums/RoutePath';
import { RouteName } from '@/enums/RouteName';

import { useUserStore } from '@/stores/user';

import SearchViewVue from '@/views/SearchView.vue';
import { searchRoutes } from './search';
import { useAuth } from '@/composables/useAuth';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: RoutePath.HOME,
      component: HomeViewVue,
      children: homeRoutes,
    },
    {
      path: RoutePath.ABOUT,
      component: AboutViewVue,
      children: aboutRoutes,
    },
    {
      path: RoutePath.SEARCH,
      component: SearchViewVue,
      children: searchRoutes,
    },
    {
      path: '/:pathMatch(.*)*',
      name: RouteName.NOT_FOUND,
      component: NotFoundView,
    },
  ],
});

router.beforeEach(async ({ meta, path, hash, query }, _, next) => {
  const { handleLogin } = useAuth();
  const userStore = useUserStore();

  try {
    const { signin, access_token: accessTokenFromQuery } = query;
    const { access_token: accessTokenFromHash } = qs.parse(hash);

    if (accessTokenFromQuery) {
      await userStore.autoSignin(accessTokenFromQuery as string);
    } else if (signin === 'auto' && accessTokenFromHash && typeof accessTokenFromHash === 'string') {
      await userStore.autoSignin(accessTokenFromHash);
    }

    userStore.setIsFetching(true);

    if (!userStore.isFetched) await userStore.setCurrentUser();

    const isLoggedIn = !!userStore.currentUser;
    if (!isLoggedIn) userStore.removeUser();

    if (path === RoutePath.MANAGE_PASSWORD) {
      if (!isLoggedIn) {
        await handleLogin(RoutePath.MANAGE_ACCOUNT);
        return;
      }

      return next({ path: RoutePath.MANAGE_ACCOUNT });
    }

    if (!path.includes('404') && !meta.isPublic && !isLoggedIn) {
      if (meta.redirectToLogin) {
        await handleLogin(path);
        return;
      }

      return next({ path: RoutePath.NOT_FOUND });
    }

    if (signin === 'auto') return next({ path });

    next();
  } catch (error) {
    console.error(error);

    next({ path });
  } finally {
    userStore.setIsFetching(false);
    userStore.setIsFetched(true);
  }
});

export default router;
