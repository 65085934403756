export const convertTimestamp = (timestamp?: number) => {
  if (!timestamp) return 'unknown timestamp';

  const date = new Date(timestamp * 1000);
  const isoStringDate = date.toISOString().split(/T/)[0];
  const actualHours = date.getHours();
  const actualMins = date.getMinutes();
  const hours = actualHours > 9 ? actualHours : `0${actualHours}`;
  const minutes = actualMins > 9 ? actualMins : `0${actualMins}`;

  return `${isoStringDate} at ${hours}:${minutes}`;
};
