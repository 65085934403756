import { APP_DOWNLOAD_LINK, APP_LANGUAGE_COOKIE_NAME, APP_URL } from './app';
import { COPYRIGHT_YEAR } from './copyright';
import { IS_DEV, IS_PROD, IS_STAGE } from './environment';
import { LANGUAGE_CODES } from './languageCodes';
import { READ_MORE_LINKS } from './readMoreLinks';
import { SOCIALS_FINLAND, SOCIALS_NORWAY, SOCIALS_DENMARK, SOCIALS_SWEDEN } from './socials';
import { ADDRESS_FINLAND, ADDRESS_NORWAY, ADDRESS_DENMARK, ADDRESS_SWEDEN } from './address';

export {
  APP_DOWNLOAD_LINK,
  APP_LANGUAGE_COOKIE_NAME,
  APP_URL,
  COPYRIGHT_YEAR,
  IS_DEV,
  IS_PROD,
  IS_STAGE,
  LANGUAGE_CODES,
  READ_MORE_LINKS,
  SOCIALS_FINLAND,
  SOCIALS_NORWAY,
  SOCIALS_DENMARK,
  SOCIALS_SWEDEN,
  ADDRESS_FINLAND,
  ADDRESS_NORWAY,
  ADDRESS_DENMARK,
  ADDRESS_SWEDEN,
};
