import { DelegateStatus } from '@/enums/Delegate';
import { Delegate, DelegateIdentifierType, RequestedAccessedDelegate, SharedDelegate } from '@/types/delegate';
import { defineStore } from 'pinia';
import { ComputedRef, Ref, computed, ref } from 'vue';
import { useUserStore } from './user';
import { usePendingRequestsStore } from './pendingRequests';
import {
  approveDelegate,
  deleteAccessedUser,
  denyDelegate,
  fetchAccessedDelegates,
  fetchSharedDelegates,
  removeSharedDelegate,
  requestAccessDelegate,
  resendOtp,
  revokeDelegate,
  validateOtp,
} from '@/services/DelegateApiService';
import { generateSearchOtp, verifySearchOtp } from '@/services/OtpApiService';

export const useDelegateStore = defineStore('delegate', () => {
  const userStore = useUserStore();
  const pendingRequestStore = usePendingRequestsStore();

  const requestedAccessedDelegate: Ref<RequestedAccessedDelegate | null> = ref(null);

  const requestedSharedDelegate: Ref<Pick<RequestedAccessedDelegate, 'key'> | null> = ref(null);

  const token = computed(() => userStore.currentUser?.accessToken);
  const currentUser = computed(() => userStore.currentUser);

  const accessedDelegateCount = ref(0);
  const accessedDelegates: Ref<Delegate[]> = ref([]);

  const sharedDelegateCount = ref(0);
  const sharedDelegates: Ref<SharedDelegate[]> = ref([]);

  const hasDelegates = computed(() => {
    return accessedDelegateCount.value > 0 || sharedDelegateCount.value > 0;
  });

  const hasSharedDelegates = computed(() => sharedDelegateCount.value > 0);

  const pendingAccessedDelegates: ComputedRef<Delegate[]> = computed((): Delegate[] => {
    return accessedDelegates.value.filter((item) => item.status === DelegateStatus.REQUESTED);
  });

  const pendingSharedDelegates: ComputedRef<Delegate[]> = computed((): Delegate[] => {
    return sharedDelegates.value.filter((item) => item.status === DelegateStatus.REQUESTED);
  });

  const pendingDelegates = computed((): Delegate[] => {
    return pendingAccessedDelegates.value.concat(pendingSharedDelegates.value);
  });

  const pendingDelegatesCount = computed(() => pendingDelegates.value.length);

  const hasPendingDelegates = computed(() => {
    return pendingAccessedDelegatesCount.value > 0 || pendingSharedDelegatesCount.value > 0;
  });

  const hasPendingSharedDelegates = computed(() => {
    return pendingSharedDelegates.value.length > 0;
  });

  const pendingAccessedDelegatesCount = computed(() => {
    return pendingAccessedDelegates.value.length;
  });

  const pendingSharedDelegatesCount = computed(() => {
    return pendingSharedDelegates.value.length;
  });

  const approvedAccessedDelagatesWithEmails = computed(() => {
    return accessedDelegates.value.filter((item) => item.status === DelegateStatus.APPROVED && !!item.email);
  });

  const approvedAccessedDelagatesWithEmailCount = computed(() => {
    return approvedAccessedDelagatesWithEmails.value.length;
  });

  const approvedAccessedDelagatesWithPhoneNumber = computed(() => {
    return accessedDelegates.value.filter((item) => item.status === DelegateStatus.APPROVED && !!item.phone_number);
  });

  const approvedAccessedDelagatesWithPhoneNumberCount = computed(() => {
    return approvedAccessedDelagatesWithPhoneNumber.value.length;
  });

  const approvedSharedDelegates = computed(() => {
    return sharedDelegates.value.filter((item) => item.status === DelegateStatus.APPROVED);
  });

  const hasApprovedSharedDelegates = computed(() => {
    return approvedSharedDelegates.value.length > 0;
  });

  const setAccessedDelegateCount = (count: number) => {
    accessedDelegateCount.value = count;
  };

  const setSharedDelegateCount = (count: number) => {
    sharedDelegateCount.value = count;
  };

  const sortDelegates = (delegates: Delegate[], key: keyof Delegate) => {
    return delegates.sort((objectA, objectB) => ((objectA[key] || 0) > (objectB[key] || 0) ? -1 : 1));
  };

  const setAccessedDelegates = async () => {
    if (!currentUser.value || !token.value) return;

    const { data } = await fetchAccessedDelegates(token.value, currentUser.value.userId);

    if (data.emails.length === 0 && data.phone_numbers.length === 0) {
      accessedDelegates.value = [];
      accessedDelegateCount.value = 0;

      return;
    }

    const mappedUsers = [...data.emails, ...data.phone_numbers].reduce(
      (accumulator: { approved: Delegate[]; others: Delegate[] }, currentValue: Delegate) => {
        if (currentValue.status === DelegateStatus.APPROVED) {
          if (currentValue.email !== currentUser.value?.email) {
            accumulator.approved = accumulator.approved.concat(currentValue);
          }

          return accumulator;
        }

        accumulator.others = accumulator.others.concat(currentValue);
        return accumulator;
      },
      { approved: [], others: [] },
    );

    const mergedDelegates = [
      ...sortDelegates(mappedUsers.approved, 'valid_until'),
      ...sortDelegates(mappedUsers.others, 'request_expiry'),
    ];

    accessedDelegates.value = mergedDelegates;
    accessedDelegateCount.value = mergedDelegates.length;
  };

  const getAccessedDelegate = (id: string) => {
    return accessedDelegates.value.find((item) => item.id === id);
  };

  const getAccessedDelegateByPhoneNumber = (phoneNumber: string) => {
    return accessedDelegates.value.find((item) => item.phone_number === phoneNumber);
  };

  const setAccessedDelegate = async (identifier: string) => {
    if (!currentUser.value || !token.value) return;

    const { data } = await requestAccessDelegate<RequestedAccessedDelegate>(
      token.value,
      identifier,
      currentUser.value.userId,
      currentUser.value.preferredLanguage,
    );

    requestedAccessedDelegate.value = data;
    if (!data) return null;

    return data;
  };

  const removeAccessedDelegate = async (delegateId: string, identifier: DelegateIdentifierType) => {
    if (!currentUser.value || !token.value) return;

    const accessedDelegate = getAccessedDelegate(delegateId);

    if (accessedDelegate) {
      const data = accessedDelegate[identifier];

      if (data) {
        await deleteAccessedUser(token.value, currentUser.value.userId, data);
        accessedDelegates.value = accessedDelegates.value.filter((item) => item.id !== delegateId);
      }
    }
  };

  const setSharedDelegates = async () => {
    if (!currentUser.value || !token.value) return;

    const { data } = await fetchSharedDelegates(token.value, currentUser.value.email);

    if (data.length === 0) {
      sharedDelegates.value = [];
      sharedDelegateCount.value = 0;

      return;
    }

    const mappedSharedDelegates = data.map((item) => {
      if (item.status === DelegateStatus.REQUESTED) return { ...item, approver: currentUser.value?.userId };
      return item;
    });

    sharedDelegates.value = mappedSharedDelegates;
    sharedDelegateCount.value = mappedSharedDelegates.length;

    if (pendingSharedDelegatesCount.value > 0) {
      pendingRequestStore.setRequestsReceived(pendingSharedDelegatesCount.value);
    }
  };

  const getSharedDelegate = (id: string) => {
    return sharedDelegates.value.find((item) => item.id === id);
  };

  const approveSharedDelegate = async (delegateId: string) => {
    if (!currentUser.value || !token.value) return;

    await approveDelegate(token.value, delegateId);
    await setSharedDelegates();

    pendingRequestStore.decreaseRequestReceived(1);
  };

  const denySharedDelegate = async (delegateId: string) => {
    if (!currentUser.value || !token.value) return;

    await denyDelegate(token.value, delegateId);
    await setSharedDelegates();

    pendingRequestStore.decreaseRequestReceived(1);
  };

  const revokeSharedDelegate = async (delegateId: string) => {
    if (!currentUser.value || !token.value) return;

    const delegate = getSharedDelegate(delegateId);

    if (delegate && delegate.user_id) {
      await revokeDelegate(token.value, currentUser.value.email, delegate.user_id);

      sharedDelegates.value = sharedDelegates.value.filter((item) => item.id !== delegateId);
      sharedDelegateCount.value = sharedDelegateCount.value - 1;
    }
  };

  const setRequestedAccessDelegate = (item: RequestedAccessedDelegate | null) => {
    requestedAccessedDelegate.value = item;
  };

  const validateDelegateOtp = async (otp: string) => {
    const requestedDelegate = requestedAccessedDelegate.value;

    if (!currentUser.value || !token.value || !requestedDelegate) return;

    await validateOtp(
      token.value,
      requestedDelegate.delegate_id,
      requestedDelegate.key,
      otp,
      currentUser.value.preferredLanguage,
    );

    await setAccessedDelegates();
  };

  const resendDelegateOtp = async () => {
    const requestedDelegate = requestedAccessedDelegate.value;

    if (!currentUser.value || !token.value || !requestedDelegate) return;

    const { data } = await resendOtp<RequestedAccessedDelegate>(
      token.value,
      requestedDelegate.delegate_id,
      currentUser.value.preferredLanguage,
    );

    requestedAccessedDelegate.value = data;
    if (!data) return null;

    return data;
  };

  const setRequestedSharedDelegate = (key: string) => {
    requestedSharedDelegate.value = { key };
  };

  const removeRequestedSharedDelegate = () => {
    requestedSharedDelegate.value = null;
  };

  const sendSearchOtp = async () => {
    const searchUser = userStore.searchUser;

    if (searchUser) {
      const identifier = searchUser.email ?? searchUser.phoneNumber;

      if (!identifier) return;

      const { data } = await generateSearchOtp(identifier);
      if (data.key) setRequestedSharedDelegate(data.key);
    }
  };

  const validateSearchOtp = async (otp: string) => {
    let isValid = false;

    const searchUser = userStore.searchUser;
    const requestedDelegate = requestedSharedDelegate.value;

    if (!searchUser || !requestedDelegate) return;

    const identifier = searchUser.email ?? searchUser.phoneNumber;
    if (!identifier) return;

    const { data } = await verifySearchOtp(identifier, requestedDelegate.key, otp);

    if (data) {
      isValid = data.isValid;

      if (data.delegates && data.delegates.length > 0) {
        sharedDelegates.value = data.delegates;
        sharedDelegateCount.value = data.delegates.length;
      }

      if (data.delegates.length === 0) {
        sharedDelegates.value = [];
        sharedDelegateCount.value = 0;
      }
    }

    return isValid;
  };

  const removeSearchSharedDelegates = (id: string) => {
    sharedDelegates.value = sharedDelegates.value.filter((item) => item.id !== id);
    sharedDelegateCount.value = sharedDelegateCount.value - 1;
  };

  const revokeSearchSharedDelegate = async (id: string) => {
    await removeSharedDelegate(id, userStore.searchUserIdentifier);
    removeSearchSharedDelegates(id);
  };

  return {
    requestedAccessedDelegate,
    accessedDelegateCount,
    accessedDelegates,
    sharedDelegateCount,
    sharedDelegates,
    hasDelegates,
    hasSharedDelegates,
    pendingAccessedDelegates,
    pendingSharedDelegates,
    pendingDelegates,
    pendingDelegatesCount,
    hasPendingDelegates,
    hasPendingSharedDelegates,
    pendingAccessedDelegatesCount,
    pendingSharedDelegatesCount,
    approvedAccessedDelagatesWithEmails,
    approvedAccessedDelagatesWithEmailCount,
    approvedAccessedDelagatesWithPhoneNumber,
    approvedAccessedDelagatesWithPhoneNumberCount,
    approvedSharedDelegates,
    hasApprovedSharedDelegates,
    setAccessedDelegateCount,
    setSharedDelegateCount,
    sortDelegates,
    setAccessedDelegates,
    getAccessedDelegate,
    getAccessedDelegateByPhoneNumber,
    setAccessedDelegate,
    getSharedDelegate,
    removeAccessedDelegate,
    setSharedDelegates,
    approveSharedDelegate,
    denySharedDelegate,
    revokeSharedDelegate,
    setRequestedAccessDelegate,
    validateDelegateOtp,
    resendDelegateOtp,
    requestedSharedDelegate,
    sendSearchOtp,
    validateSearchOtp,
    setRequestedSharedDelegate,
    removeRequestedSharedDelegate,
    revokeSearchSharedDelegate,
  };
});
