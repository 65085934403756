import { APP_URL } from '@/constants/app';
import { Environment } from '@/enums/Environment';

export const getEnvironment = () => {
  const hostName = window.location.hostname;

  if (hostName.includes(APP_URL)) {
    if (hostName.includes('stage')) return Environment.STAGE;
    if (!hostName.includes('dev') && !hostName.includes('stage')) return Environment.PRODUCTION;
  }

  return Environment.DEV;
};
