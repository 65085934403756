<template>
  <section class="loader__wrapper">
    <pn-spinner />
  </section>
</template>

<style lang="scss" scoped>
.loader {
  &__wrapper {
    display: flex;
    justify-content: center;
    height: 30vh;
    align-items: center;
  }
}
</style>
