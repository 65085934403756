import { RouteRecordRaw } from 'vue-router';

import { useUserStore } from '@/stores/user';
import { useDevicesStore } from '@/stores/devices';
import { usePendingRequestsStore } from '@/stores/pendingRequests';
import { useSessionsStore } from '@/stores/sessions';
import { useSwitchUserStore } from '@/stores/switchUser';

import Home from '@/containers/Home/index.vue';
import { RouteName } from '@/enums/RouteName';
import { RoutePath } from '@/enums/RoutePath';

export const homeRoutes: RouteRecordRaw[] = [
  {
    path: '',
    name: RouteName.HOME,
    component: Home,
    meta: {
      isPublic: true,
    },
  },
  {
    path: RoutePath.MANAGE_ACCOUNT,
    name: RouteName.MANAGE_ACCOUNT,
    component: () => import('@/containers/Account/index.vue'),
    meta: { isPublic: false, redirectToLogin: true },
  },
  {
    path: RoutePath.MANAGE_DEVICES,
    name: RouteName.MANAGE_DEVICES,
    component: () => import('@/containers/Device/index.vue'),
    meta: { isPublic: false, redirectToLogin: true },
    beforeEnter: () => {
      const userStore = useUserStore();

      if (userStore.hasUser) {
        const deviceStore = useDevicesStore();

        if (deviceStore.hasDevices) return true;
        return { path: RoutePath.NOT_FOUND };
      }
    },
  },
  {
    path: RoutePath.MANAGE_PENDING_REQUESTS,
    name: RouteName.MANAGE_PENDING_REQUESTS,
    component: () => import('@/containers/PendingRequest/index.vue'),
    meta: { isPublic: false, redirectToLogin: true },
    beforeEnter: () => {
      const userStore = useUserStore();

      if (userStore.hasUser) {
        const pendingRequestsStore = usePendingRequestsStore();

        if (pendingRequestsStore.hasRequestsSent || pendingRequestsStore.hasPendingRequests) return true;
        return { path: RoutePath.NOT_FOUND };
      }
    },
  },
  {
    path: RoutePath.MANAGE_SESSIONS,
    name: RouteName.MANAGE_SESSIONS,
    component: () => import('@/containers/Session/index.vue'),
    meta: { isPublic: false, redirectToLogin: true },
    beforeEnter: () => {
      const userStore = useUserStore();

      if (userStore.hasUser) {
        const sessionsStore = useSessionsStore();

        if (sessionsStore.hasSessions) return true;
        return { path: RoutePath.NOT_FOUND };
      }
    },
  },
  {
    path: RoutePath.MANAGE_SWITCH_USER,
    name: RouteName.MANAGE_SWITCH_USER,
    component: () => import('@/containers/SwitchUser/index.vue'),
    meta: { isPublic: false, redirectToLogin: true },
    beforeEnter: () => {
      const userStore = useUserStore();

      if (userStore.hasUser) {
        const switchUserStore = useSwitchUserStore();

        if (switchUserStore.hasSwitchUser) return true;
        return { path: RoutePath.NOT_FOUND };
      }
    },
  },
];
