import { patch, post } from './HttpService';

interface Credentials {
  userName: string;
  password: string;
}

export const login = async (body: Credentials) => {
  return post({
    url: `/logins/auth`,
    body,
    config: {
      headers: { 'Content-Type': 'application/json' },
    },
  });
};

export const changePassword = async (token: string, { userName, password }: Credentials) => {
  return patch({
    url: `/logins/${encodeURIComponent(userName)}`,
    body: { password },
    config: {
      headers: { 'Content-Type': 'application/json' },
    },
    token,
  });
};
