export enum RoutePath {
  HOME = '/',
  MANAGE_ACCOUNT = '/manage-account',
  MANAGE_PASSWORD = '/manage-password',
  MANAGE_DELEGATES = '/connected-accounts',
  MANAGE_DEVICES = '/devices',
  MANAGE_PENDING_REQUESTS = '/pending-requests',
  MANAGE_SESSIONS = '/sessions',
  MANAGE_SWITCH_USER = '/switch-user',
  ABOUT = '/about',
  PROTECT_ACCOUNT = '/protect-your-account',
  TERMS_CONDITIONS = '/terms-and-conditions',
  SEARCH = '/search',
  SEARCH_DELEGATES = '/search/connected-accounts',
  ACCOUNT_DELETED_CONFIRMATION = '/account-deleted-confirmation',
  NOT_FOUND = '/404',
}
