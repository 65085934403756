import { SwitchUserRequest } from '@/types/switchUser';
import { get, put, post } from './HttpService';
import { grantTypes } from '@postnord/iam-constants';

export const fetchSwitchUserRequests = async (token: string, userId: string) => {
  return get<SwitchUserRequest[]>({ url: `/switch-user/${userId}/pending`, token });
};

export const fetchSwitchUserTokens = async (token: string) => {
  return get({ url: `/tokens?grant_type=${encodeURIComponent(grantTypes.SWITCH_USER)}`, token });
};

export const executeSwitchUser = async (token: string, requestId: string, isApprove: boolean) => {
  return put({ url: `/switch-user/${requestId}/${isApprove ? 'approve' : 'deny'}`, token });
};

export const revokeSwitchUserToken = async (token: string) => {
  return post({
    url: `/me/switch-user/revoke`,
    body: { token },
  });
};
