import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { useCookies } from 'vue3-cookies';
import { APP_LANGUAGE_COOKIE_NAME } from '@/constants/app';
import {
  ADDRESS_DENMARK,
  ADDRESS_FINLAND,
  ADDRESS_NORWAY,
  ADDRESS_SWEDEN,
  SOCIALS_DENMARK,
  SOCIALS_FINLAND,
  SOCIALS_NORWAY,
  SOCIALS_SWEDEN,
} from '@/constants';
import { LanguageCode } from '@/enums/Language';

export const useAppStore = defineStore('app', () => {
  const { cookies } = useCookies();

  const language = ref(cookies.get(APP_LANGUAGE_COOKIE_NAME) ?? 'en');

  const setLanguage = (value: string) => {
    if (!value) {
      return;
    }

    language.value = value;
    cookies.set(APP_LANGUAGE_COOKIE_NAME, value);
  };

  const address = computed(() => {
    if (language.value === LanguageCode.Suomi) return ADDRESS_FINLAND;
    if (language.value === LanguageCode.Norsk) return ADDRESS_NORWAY;
    if (language.value === LanguageCode.Dansk) return ADDRESS_DENMARK;

    return ADDRESS_SWEDEN;
  });

  const socials = computed(() => {
    if (language.value === LanguageCode.Suomi) return SOCIALS_FINLAND;
    if (language.value === LanguageCode.Norsk) return SOCIALS_NORWAY;
    if (language.value === LanguageCode.Dansk) return SOCIALS_DENMARK;

    return SOCIALS_SWEDEN;
  });

  return { language, setLanguage, address, socials };
});
