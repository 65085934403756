import { SocialLink } from '@/types/socials';

export const SOCIALS_SWEDEN = {
  facebookUrl: 'https://www.facebook.com/postnordsverige',
  linkedinUrl: 'https://www.linkedin.com/company/postnord-sverige',
  xUrl: 'https://x.com/PostNordSverige',
} as SocialLink;

export const SOCIALS_DENMARK = {
  facebookUrl: 'https://www.facebook.com/PostNordDK',
  linkedinUrl: 'https://www.linkedin.com/company/post-danmark',
  instagramUrl: 'https://www.instagram.com/postnorddk',
  xUrl: 'https://x.com/postnorddk',
} as SocialLink;

export const SOCIALS_FINLAND = {
  facebookUrl: 'https://www.facebook.com/postnordfinland',
  linkedinUrl: 'https://www.linkedin.com/company/posten-logistik-scm-oy',
  xUrl: 'https://x.com/PostNordFinland',
} as SocialLink;

export const SOCIALS_NORWAY = {
  facebookUrl: 'https://www.facebook.com/PostNordNorge',
  linkedinUrl: 'https://www.linkedin.com/company/postnord-norge',
  xUrl: 'https://x.com/PostNordNorgeAS',
} as SocialLink;
