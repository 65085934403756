import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const usePendingRequestsStore = defineStore('pendingRequests', () => {
  const requestsSent = ref(0);
  const requestsReceived = ref(0);

  const hasRequestsSent = computed(() => requestsSent.value > 0);

  const hasRequestsReceived = computed(() => requestsReceived.value > 0);

  const hasPendingRequests = computed(() => hasRequestsSent.value || hasRequestsReceived.value);

  const setRequestsSent = (value: number) => {
    requestsSent.value = value;
  };

  const decreaseRequestSent = (value: number) => {
    requestsSent.value = requestsSent.value - value;
  };

  const setRequestsReceived = (value: number) => {
    requestsReceived.value = value;
  };

  const decreaseRequestReceived = (value: number) => {
    requestsReceived.value = requestsReceived.value - value;
  };

  return {
    requestsSent,
    requestsReceived,
    hasRequestsSent,
    hasRequestsReceived,
    hasPendingRequests,
    setRequestsSent,
    decreaseRequestSent,
    setRequestsReceived,
    decreaseRequestReceived,
  };
});
