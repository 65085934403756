import { RouteName } from '@/enums/RouteName';
import { RouteRecordRaw } from 'vue-router';
import { RoutePath } from '@/enums/RoutePath';

import Search from '@/containers/Search/index.vue';
import SearchDelegate from '@/containers/Search/SearchDelegate/index.vue';
import { useUserStore } from '@/stores/user';

export const searchRoutes: RouteRecordRaw[] = [
  {
    path: '',
    name: RouteName.SEARCH,
    component: Search,
    meta: {
      isPublic: true,
    },
  },
  {
    path: RoutePath.SEARCH_DELEGATES,
    name: RouteName.SEARCH_DELEGATES,
    component: SearchDelegate,
    meta: {
      isPublic: true,
    },
    beforeEnter: () => {
      const userStore = useUserStore();
      const searchUser = userStore.searchUser;

      if (searchUser && (searchUser.email || searchUser.phoneNumber)) return true;
      return { path: RoutePath.NOT_FOUND };
    },
  },
];
