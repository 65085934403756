<script setup lang="ts"></script>

<template>
  <div class="callback">
    <pn-spinner size="6"></pn-spinner>
  </div>
</template>

<style lang="scss" scoped>
.callback {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
</style>
