import { defineStore } from 'pinia';
import { Ref, ref, computed } from 'vue';

import { useUserStore } from './user';
import { fetchDeviceModel, fetchDevices, removeDevice } from '@/services/DeviceApiService';
import { Device } from '@/types/devices';

export const useDevicesStore = defineStore('device', () => {
  const userStore = useUserStore();

  const token = computed(() => userStore.currentUser?.accessToken);
  const currentUser = computed(() => userStore.currentUser);

  const count = ref(0);
  const devices: Ref<Device[]> = ref([]);

  const hasDevices = computed(() => count.value > 0);

  const setCount = (value: number) => {
    count.value = value;
  };

  const getDevices = async () => {
    if (!currentUser.value || !token.value) return;

    const { data } = await fetchDevices(token.value, currentUser.value.userId);

    if (data.length === 0) {
      devices.value = [];
      count.value = 0;

      return;
    }

    const mappedDevices = !hasDevices.value
      ? []
      : await Promise.all(
          data.map(async (device) => {
            try {
              if (!token.value) return device;

              const platform = device.device_os === 'Android' ? 'Android' : 'iOS';
              const { data } = await fetchDeviceModel(token.value, device.device_name, platform);

              return { ...device, marketing_name: data.marketing_name };
            } catch (error) {
              return device;
            }
          }),
        );

    devices.value = mappedDevices;
    count.value = mappedDevices.length;
  };

  const remove = async (deviceId: string) => {
    if (!currentUser.value || !token.value) return;

    await removeDevice(token.value, deviceId);

    devices.value = devices.value.filter((item) => item.app_installation_id !== deviceId);
    count.value = devices.value.length;
  };

  return {
    count,
    devices,
    hasDevices,
    setCount,
    getDevices,
    remove,
  };
});
