import { User, UserCheck } from '@/types/user';
import { patch, post, put, remove } from './HttpService';

export const autoSigninUser = async (accessToken: string) => {
  return post({
    url: `/api/auto-sign-in`,
    body: {
      accessToken,
    },
  });
};

export const fetchCurrentUser = async () => {
  return post<never, User>({ url: `/me/user` });
};

export const patchCurrentUser = async (token: string, identifier: string, body: Partial<User>) => {
  return patch({
    url: `/persons/${encodeURIComponent(identifier)}`,
    config: {
      headers: { 'Content-Type': 'application/json' },
    },
    body,
    token,
  });
};

export const patchPrimaryEmail = async (token: string, userId: string, toEmail: string) => {
  return patch({
    url: `/persons/${userId}/change-email`,
    body: { toEmail },
    token,
  });
};

export const patchPrimaryPhone = <T>(token: string, userId: string, phoneNumber: string, isNew: boolean) => {
  const queryParams = isNew ? '?isNew=true' : '';

  return patch<null, T>({
    url: `/persons/${userId}/set-as-primary/${encodeURIComponent(phoneNumber)}${queryParams}`,
    token,
  });
};

export const levelDown = (token: string, userId: string) => {
  return put({
    url: `/persons/${userId}/level-down`,
    body: null,
    token,
  });
};

export const deleteUser = async (token: string, userId: string) => {
  return remove({
    url: `/persons/${userId}`,
    token,
  });
};

export const checkUser = async (identifier: string) => {
  return post<{ identifier: string }, UserCheck>({
    url: `/me/user/check`,
    body: { identifier },
  });
};
