import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { useAccountStore } from './account';
import { useUserStore } from './user';
import { useDelegateStore } from './delegate';

import router from '@/router';
import { Intent } from '@/enums/Intent';
import { generateOtp, verifyOtp } from '@/services/OtpApiService';
import { RequestedAccessedDelegate } from '@/types/delegate';
import { RouteName } from '@/enums/RouteName';
import { useI18n } from 'vue-i18n';
import { useToastStore } from '@/stores/toast';
import { AxiosError } from 'axios';

export const useOtpStore = defineStore('otp', () => {
  const accountStore = useAccountStore();
  const userStore = useUserStore();
  const delegateStore = useDelegateStore();
  const { t } = useI18n();
  const toast = useToastStore();

  const isSending = ref(false);
  const isSent = ref(false);
  const isValidating = ref(false);
  const isResend = ref(false);
  const isResending = ref(false);

  const intent = computed(() =>
    accountStore.isEmail
      ? Intent.ChangeEmail
      : !accountStore.isDeleteAccount
        ? Intent.ChangePhone
        : Intent.RemoveAccount,
  );

  const code = ref('');

  const handleInput = (value: string) => (code.value = value);

  const send = async () => {
    try {
      if (!isResend.value) isSending.value = true;
      if (isResend.value) isResending.value = true;

      if (!userStore.currentUser) return;

      const token = userStore.currentUser.accessToken;
      if (!token || !accountStore.identifier) return;

      let data: RequestedAccessedDelegate | null | undefined = null;

      if (!accountStore.isAddNew) {
        if (accountStore.isSeeSharedAccess) {
          await delegateStore.sendSearchOtp();
        }

        if (!accountStore.isSeeSharedAccess) {
          await generateOtp(token, {
            intent: intent.value,
            identifier: accountStore.identifier,
            loggedInUserId: userStore.currentUser.userId,
          });
        }
      }

      if (accountStore.isAddNew) {
        data = await delegateStore.setAccessedDelegate(accountStore.identifier);
      }

      isSent.value = true;

      return data;
    } catch (error) {
      const axiosError = error as AxiosError<{ message: string; code: number; subcode: number }>;
      if (axiosError?.response?.status === 429) {
        toast.warning({ message: t('error_modal.action-limit-reached-text') });
      } else {
        throw axiosError;
      }
    } finally {
      if (!isResend.value) isSending.value = false;
      if (isResend.value) {
        isResend.value = false;
        isResending.value = false;
      }
    }
  };

  const validate = async () => {
    try {
      isValidating.value = true;

      if (!userStore.currentUser) return;

      const token = userStore.currentUser.accessToken;
      if (!token || !accountStore.identifier) return;

      if (!accountStore.isSeeSharedAccess) {
        if (accountStore.isAddNew) {
          await delegateStore.validateDelegateOtp(code.value);
        }

        if (!accountStore.isAddNew) {
          await verifyOtp(token, {
            code: code.value,
            identifier: accountStore.identifier,
            intent: intent.value,
          });
        }

        accountStore.setIsVerified();
      }

      if (accountStore.isSeeSharedAccess) {
        const isValid = await delegateStore.validateSearchOtp(code.value);
        if (!isValid) {
          isResend.value = true;
          return;
        }

        router.push({ name: RouteName.SEARCH_DELEGATES });
        accountStore.resetState();
        resetState();
      }
    } catch (error) {
      isResend.value = true;

      throw error;
    } finally {
      isValidating.value = false;
    }
  };

  const resetState = () => {
    isSending.value = false;
    isSent.value = false;
    isValidating.value = false;
    isResend.value = false;
    isResending.value = false;
  };

  return {
    isSending,
    isSent,
    isValidating,
    isResend,
    isResending,
    handleInput,
    send,
    validate,
    resetState,
  };
});
