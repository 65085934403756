import { LanguageCode, Languages } from '@/enums/Language';

export const parseLanguageCode = (language: string, alt = false) => {
  if (language === LanguageCode.Svenska) return alt ? 'sv' : Languages.Svenska;
  if (language === LanguageCode.Dansk) return alt ? 'da' : Languages.Dansk;
  if (language === LanguageCode.Suomi) return alt ? 'fi' : Languages.Suomi;
  if (language === LanguageCode.Norsk) return alt ? 'nb' : Languages.Norsk;

  return alt ? 'en' : Languages.English;
};
