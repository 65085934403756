<template>
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.5 0H5.5V2H2.5V11C2.5 12.6569 3.84315 14 5.5 14H14.5V11H16.5V16H5.5C2.73858 16 0.5 13.7614 0.5 11V0ZM13.0858 2H8.5V0H16.5V8H14.5V3.41421L7.20711 10.7071L5.79289 9.29289L13.0858 2Z"
      fill="#005D92"
    />
  </svg>
</template>
