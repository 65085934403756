import { Delegate } from '@/types/delegate';
import { post } from './HttpService';

interface GenerateOtpRequest {
  identifier: string;
  langauage?: string;
  template?: string;
  template_data?: CreateOwnedDelegateTemplateData;
  intent?: string;
  loggedInUserId?: string;
}

interface CreateOwnedDelegateTemplateData {
  inviter_email: string;
}

interface VerifyOtpRequest {
  identifier: string;
  code: string;
  intent?: string;
}

export const generateSearchOtp = async (identifier: string) => {
  return post<{ identifier: string; intent: string }, { key: string }>({
    url: `/me/otp/generate`,
    body: { identifier, intent: 'login' },
  });
};

export const verifySearchOtp = async (identifier: string, key: string, code: string) => {
  return post<{ identifier: string; key: string; code: string }, { isValid: boolean; delegates: Delegate[] }>({
    url: `/me/otp/verify`,
    body: { identifier, key, code },
  });
};

export const generateOtp = async (token: string, body: GenerateOtpRequest) => {
  return post({
    url: `/otp-profile/generate`,
    config: {
      headers: { 'Content-Type': 'application/json' },
    },
    body,
    token,
  });
};

export const verifyOtp = (token: string, body: VerifyOtpRequest) => {
  return post({
    url: `/otp-profile/verify`,
    config: {
      headers: { 'Content-Type': 'application/json' },
    },
    body,
    token,
  });
};
