import { getApiUrl } from '@/utils/getApiUrl';
import { HttpParamater } from '@/types/http';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { v4 as uuidv4 } from 'uuid';

export const http = (token?: string) => {
  const HEADER_ORIGINAL_CLIENT_ID = 'X-Original-Client-Id';
  const HEADER_REQUEST_ID = 'X-Request-Id';
  const HEADER_AUTHORIZATION = 'Authorization';

  const axiosInstance = axios.create({
    baseURL: getApiUrl(),
  });

  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => Promise.reject(error),
  );

  // TODO: Check common header values
  axiosInstance.defaults.headers.common[HEADER_ORIGINAL_CLIENT_ID] =
    process.env.VUE_APP_PROFILE_CLIENT_ID || 'SPAProfileAppFDwaWFxEPkKdBb0J1';

  axiosInstance.defaults.headers.common[HEADER_REQUEST_ID] = uuidv4();

  if (token) axiosInstance.defaults.headers.common[HEADER_AUTHORIZATION] = `Bearer ${token}`;

  return axiosInstance;
};

export const get = async <T>({ url, config, token }: HttpParamater<unknown>): Promise<AxiosResponse<T>> => {
  return http(token).get(url, config);
};

export const patch = async <B, T>({ url, body, config, token }: HttpParamater<B>): Promise<AxiosResponse<T>> => {
  return http(token).patch(url, body, config);
};

export const post = async <B, T>({ url, body, config, token }: HttpParamater<B>): Promise<AxiosResponse<T>> => {
  return http(token).post(url, body, config);
};

export const put = async <B, T>({ url, body, config, token }: HttpParamater<B>): Promise<AxiosResponse<T>> => {
  return http(token).put(url, body, config);
};

export const remove = async <B, T>({ url, config, token }: HttpParamater<B>): Promise<AxiosResponse<T>> => {
  return http(token).delete(url, config);
};
