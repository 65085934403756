<template>
  <header class="modal__header modal__header--centered modal__header--no-margin">
    <h1>{{ $t('manage_account_page.account_deletion_title') }}</h1>

    <p class="modal__header__caption--left">
      <strong>{{ $t('profile_page.manage-account-delete-emphasis') }}</strong>
      {{ $t('profile_page.manage-account-delete-info') }}
    </p>
  </header>
</template>
