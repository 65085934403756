import { defineStore } from 'pinia';
import { Ref, ref, computed } from 'vue';

import { useUserStore } from './user';
import {
  fetchSessions,
  fetchClientSessions,
  removeSession,
  removeClientSession,
  logout,
} from '@/services/SessionService';
import { Session } from '@/types/session';
import { ClientSession } from '@/types/clientSession';

export const useSessionsStore = defineStore('session', () => {
  const userStore = useUserStore();

  const token = computed(() => userStore.currentUser?.accessToken);
  const currentUser = computed(() => userStore.currentUser);

  const count = ref(0);
  const setCount = (value: number) => (count.value = value);

  const sessionId: Ref<string | null> = ref(null);
  const setSessionId = (value: string | null) => (sessionId.value = value);

  const sessions: Ref<Session[]> = ref([]);
  const clientSessions: Ref<ClientSession[]> = ref([]);

  const pastSessions = computed(() => {
    if (sessions.value.length > 0) {
      return sessions.value
        .filter((item) => item.session_id !== sessionId.value)
        .sort((a, b) => {
          const firstTimestamp = a.loggedInTime ?? a.loginAt;
          const secondTimestamp = b.loggedInTime ?? b.loginAt;

          if (firstTimestamp && secondTimestamp) return secondTimestamp - firstTimestamp;

          return 0;
        });
    }

    return [];
  });

  const currentSession = computed(() => {
    const session = sessions.value.find((item) => item.session_id === sessionId.value);

    if (session) return session;

    return null;
  });

  const hasSessions = computed(() => count.value > 0);
  const hasClientSessions = computed(() => count.value > 0);

  const getSessions = async () => {
    if (!currentUser.value || !token.value) return;

    const { data } = await fetchSessions(token.value, currentUser.value.userId);

    sessions.value = data;
  };

  const getClientSessions = async () => {
    if (!currentUser.value || !token.value) return;

    const { data } = await fetchClientSessions(token.value);

    clientSessions.value = data;
  };

  const remove = async (sessionId: string) => {
    if (!currentUser.value || !token.value) return;

    await removeSession(token.value, sessionId);

    sessions.value = sessions.value.filter((item) => item.session_id !== sessionId);
    count.value = sessions.value.length;
  };

  const removeClient = async (clientId: string) => {
    if (!currentUser.value || !token.value) return;

    await removeClientSession(token.value, clientId);

    clientSessions.value = clientSessions.value.filter((item) => item.clientId !== clientId);
  };

  const logoutEverything = async () => {
    if (!token.value) return;

    await logout(token.value);
  };

  return {
    pastSessions,
    currentSession,
    clientSessions,
    sessionId,
    setSessionId,
    hasSessions,
    hasClientSessions,
    setCount,
    getSessions,
    getClientSessions,
    remove,
    removeClient,
    logoutEverything,
  };
});
