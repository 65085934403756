<template>
  <div class="separator"></div>
</template>

<style lang="scss" scoped>
.separator {
  display: block;
  border-bottom: 0.06rem solid #e9e6e5;
  width: 100%;
}
</style>
