import { RouteName } from '@/enums/RouteName';
import { RoutePath } from '@/enums/RoutePath';
import { RouteRecordRaw } from 'vue-router';

export const aboutRoutes: RouteRecordRaw[] = [
  {
    path: '',
    name: RouteName.ABOUT,
    component: () => import('@/containers/About/index.vue'),
    meta: { isPublic: true },
  },
  {
    path: RoutePath.PROTECT_ACCOUNT,
    name: RouteName.PROTECT_ACCOUNT,
    component: () => import('@/containers/About/ProtectAccount.vue'),
    meta: { isPublic: true },
  },
  {
    path: RoutePath.TERMS_CONDITIONS,
    name: RouteName.TERMS_CONDITIONS,
    component: () => import('@/containers/About/TermsAndConditions.vue'),
    meta: { isPublic: true },
  },
  {
    path: RoutePath.ACCOUNT_DELETED_CONFIRMATION,
    name: RouteName.ACCOUNT_DELETED_CONFIRMATION,
    component: () => import('@/containers/About/AccountDeletedConfirmation.vue'),
    meta: { isPublic: true },
  },
];
