import { get, post, put, remove } from './HttpService';
import { AccessedDelegate, SharedDelegate } from '@/types/delegate';

export const fetchAccessedDelegates = (token: string, userId: string) => {
  return get<AccessedDelegate>({
    url: `/persons/${userId}/has-access-to?withFollowers=true&hasPrimaryPhoneNumber=false`,
    token,
  });
};

export const fetchSharedDelegates = (token: string, identifier: string) => {
  return get<SharedDelegate[]>({
    url: `/persons/${encodeURIComponent(identifier)}/can-be-accessed-by`,
    token,
  });
};

export const deleteAccessedUser = (token: string, userId: string, identifier: string) => {
  return remove({
    url: `/persons/${userId}/has-access-to/${encodeURIComponent(identifier)}`,
    token,
  });
};

export const revokeDelegate = (token: string, identifier: string, userId: string) => {
  return remove({
    url: `/persons/${encodeURIComponent(identifier)}/can-be-accessed-by/${userId}`,
    token,
  });
};

export const removeSharedDelegate = (delegateId: string, identifier: string) => {
  return remove({
    url: `/me/delegates/${delegateId}/remove-by-identifier/${encodeURIComponent(identifier)}`,
  });
};

export const requestAccessDelegate = <T>(token: string, identifier: string, userId: string, language = 'en') => {
  return post<null, T>({
    url: `/persons/${userId}/has-access-to/${encodeURIComponent(identifier)}?language=${language}`,
    config: {
      headers: { 'Content-Type': 'application/json' },
    },
    token,
  });
};

export const validateOtp = (token: string, delegateId: string, key: string, code: string, language = 'en') => {
  return put({
    url: `/delegates/${delegateId}/approve-by-otp?language=${language}`,
    body: {
      key,
      code,
      language,
    },
    config: {
      headers: { 'Content-Type': 'application/json' },
    },
    token,
  });
};

export const resendOtp = <T>(token: string, delegateId: string, language = 'en') => {
  return post<null, T>({
    url: `/delegates/${delegateId}/resend-otp?language=${language}`,
    config: {
      headers: { 'Content-Type': 'application/json' },
    },
    token,
  });
};

export const approveDelegate = (token: string, delegateId: string) => {
  return put({
    url: `/delegates/${delegateId}/approve`,
    token,
  });
};

export const denyDelegate = (token: string, delegateId: string) => {
  return put({
    url: `/delegates/${delegateId}/deny`,
    token,
  });
};
